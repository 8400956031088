<template>
  <div>
    <a-menu
      class="menu-content"
      v-model:selectedKeys="selectedKeys"
      style="width: 154px"
      mode="inline"
      :open-keys="openKeys"
      @openChange="onOpenChange"
    >
      <a-sub-menu class="Zone_1" key="sub1">
        <template class="Zone_1-tittle" #title>Zone 1</template>
        <div v-for="vl in dataZone1" :key="vl.id">
          <a-menu-item
            @click="select(vl.key, vl.id)"
            class="Zone_1-item"
            :key="vl.key"
            :class="{ 'ant-menu-item-selected': selectCity === vl.key }"
            >{{ vl.name }}</a-menu-item
          >
        </div>
      </a-sub-menu>

      <a-sub-menu class="Zone_2" key="sub2">
        <template class="Zone_2-tittle" #title>Zone 2</template>
        <div v-for="vl in dataZone2" :key="vl.id">
          <a-menu-item
            @click="select(vl.key, vl.id)"
            class="Zone_2-item"
            :key="vl.key"
            :class="{ 'ant-menu-item-selected': selectCity === vl.key }"
            >{{ vl.name }}</a-menu-item
          >
        </div>
      </a-sub-menu>

      <a-sub-menu class="Zone_3" key="sub3">
        <template class="Zone_3-tittle" #title>Zone 3</template>
        <div v-for="vl in dataZone3" :key="vl.id">
          <a-menu-item
            @click="select(vl.key, vl.id)"
            class="Zone_3-item"
            :key="vl.key"
            :class="{ 'ant-menu-item-selected': selectCity === vl.key }"
            >{{ vl.name }}</a-menu-item
          >
        </div>
      </a-sub-menu>

      <a-sub-menu class="Zone_4" key="sub4">
        <template class="Zone_4-tittle" #title>Zone 4</template>
        <div v-for="vl in dataZone4" :key="vl.id">
          <a-menu-item
            @click="select(vl.key, vl.id)"
            class="Zone_4-item"
            :key="vl.key"
            :class="{ 'ant-menu-item-selected': selectCity === vl.key }"
            >{{ vl.name }}</a-menu-item
          >
        </div>
      </a-sub-menu>

      <a-sub-menu class="Zone_5" key="sub5">
        <template class="Zone_5-tittle" #title>Zone 5</template>
        <div v-for="vl in dataZone5" :key="vl.id">
          <a-menu-item
            @click="select(vl.key, vl.id)"
            class="Zone_5-item"
            :key="vl.key"
            :class="{ 'ant-menu-item-selected': selectCity === vl.key }"
            >{{ vl.name }}</a-menu-item
          >
        </div>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from 'vue'
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
  },
  props: ['keyVl', 'OpenKeys'],
  emits: ['updateZon'],
  setup(props, context) {
    // varibal //////////////
    const { keyVl } = toRefs(props)
    const { OpenKeys } = toRefs(props)

    let selectCity = keyVl.value

    const dataZone1 = ref([
      { key: 1, id: 1, name: 'Rabat' },
      { key: 2, id: 1, name: 'El Hoceima' },
      { key: 3, id: 1, name: 'Berkane' },
    ])
    const dataZone2 = ref([
      { key: 4, id: 2, name: 'Fes' },
      { key: 5, id: 2, name: 'Taza' },
      { key: 6, id: 2, name: 'Oujda' },
      { key: 7, id: 2, name: 'El Jadida' },
      { key: 8, id: 2, name: 'Khenifra' },
      { key: 9, id: 2, name: 'Essaouira' },
      { key: 10, id: 2, name: 'Taroudant' },
      { key: 11, id: 2, name: 'Guelmim' },
    ])
    const dataZone3 = ref([
      { key: 12, id: 3, name: 'Midlet' },
      { key: 13, id: 3, name: 'Beni Mellal' },
      { key: 14, id: 3, name: 'Marrakesh' },
      { key: 15, id: 3, name: 'Laayoune' },
      { key: 16, id: 3, name: 'samara' },
    ])
    const dataZone4 = ref([
      { key: 17, id: 4, name: 'Bouarfa' },
      { key: 18, id: 4, name: 'Erfoud' },
      { key: 19, id: 4, name: 'Ouarzazate' },
      { key: 20, id: 4, name: 'Zagora' },
      { key: 21, id: 4, name: 'Tata' },
      { key: 22, id: 4, name: 'Mahbés' },
      { key: 23, id: 4, name: 'Bir lahlou' },
    ])
    const dataZone5 = ref([
      { key: 24, id: 5, name: 'Tetouan' },
      { key: 25, id: 5, name: 'Agadir' },
    ])
    const state = reactive({
      rootSubmenuKeys: ['sub1', 'sub2', 'sub3', 'sub4', 'sub5'],
      openKeys: OpenKeys.value,
      selectedKeys: [],
    })
    ///////////////////////////////
    const onOpenChange = (openKeys) => {
      const latestOpenKey = openKeys.find(
        (key) => state.openKeys.indexOf(key) === -1
      )
      if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        state.openKeys = openKeys
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    }

    const select = (vl, id) => {
      selectCity = vl

      context.emit('updateZon', {
        id,
        selectCity,
        openKeys: state.openKeys,
      })
    }
    return {
      ...toRefs(state),
      onOpenChange,
      select,
      dataZone1,
      dataZone2,
      dataZone3,
      dataZone4,
      dataZone5,
      selectCity,
    }
  },
})
</script>

<style>
.menu-content .Zone_1,
.menu-content .Zone_2,
.menu-content .Zone_3,
.menu-content .Zone_4,
.menu-content .Zone_5 {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.menu-content .Zone_1,
.menu-content .Zone_2,
.menu-content .Zone_3,
.menu-content .Zone_4,
.menu-content .Zone_5 {
  background-color: #e22623;
}
.menu-content .Zone_1 {
  border-radius: 3.66473px 3.66473px 0px 0px;
}
.menu-content .Zone_5 {
  border-radius: 0px 0px 3.66473px 3.66473px;
}
.menu-content .ant-menu-item-selected {
  background-color: #f5f5f5 !important;
  color: #000000 !important;
}

.menu-content .ant-menu-submenu-selected {
  background-color: #000000;
  color: white;
}

.menu-content .ant-menu-submenu-active,
.menu-content .ant-menu-submenu-open {
  background-color: #ffb800;
  color: white !important;
}
.menu-content .ant-menu-submenu-active::after,
.menu-content .ant-menu-submenu-active::before {
  color: #fff;
}
.ant-menu-item::after {
  border-right: none !important;
}

/* .ant-menu-submenu-selected {
  background-color: #000000 !important;
  color: white !important;
} */
.menu-content .Zone_1-item:hover,
.menu-content .Zone_2-item:hover,
.menu-content .Zone_3-item:hover,
.menu-content .Zone_4-item:hover,
.menu-content .Zone_5-item:hover {
  background-color: #f5f5f5 !important;
  color: #000000 !important;
}
.menu-content .ant-menu-submenu-arrow {
  color: #fff;
}
.menu-content
  > .ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow {
  color: #000000;
}

.menu-content .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.menu-content .ant-menu-submenu-arrow:hover,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: white !important;
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  width: 7px !important;
  height: 2.2px !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  font-size: 14px;
}
.ant-menu-sub.ant-menu-inline {
  background-color: #fff !important;
}
@media screen and (max-width: 500px) {
  .menu-content {
    width: 194px !important;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
  .ant-menu-inline.ant-menu-root
    .ant-menu-submenu-title
    > .ant-menu-title-content {
    font-size: 16px;
  }
}
</style>
