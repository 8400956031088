<template>
  <div class="svg-ete">
    <img :src="battre" class="svg-ete" />
  </div>
</template>

<script>
import Battre from './PngIcon/battre.svg'
export default {
  data() {
    return {
      battre: Battre,
    }
  },
}
</script>

<style scoped>
.svg-ete {
  width: 45.68px;
  height: 27.35px;
}
@media screen and (max-width: 909px) {
  .svg-ete {
    width: 40px;
    height: 23px;
  }
}
</style>
