<template>
  <div class="svg-ete">
    <img :src="ete" class="svg-ete" />
  </div>
</template>

<script>
import Ete from './PngIcon/iconEte.png'
export default {
  data() {
    return {
      ete: Ete,
    }
  },
}
</script>

<style scoped>
.svg-ete {
  height: 38px;
  width: 38px;
}
</style>
