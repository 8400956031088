<template>
  <div class="svg-ete">
    <img :src="icon" class="svg-ete" />
  </div>
</template>

<script>
import Icon from './PngIcon/iconRemov.png'
export default {
  data() {
    return {
      icon: Icon,
    }
  },
}
</script>

<style scoped>
.svg-ete {
  width: 24px;
  height: 24px;
}
</style>
