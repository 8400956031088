<template>
  <div class="svg-ete">
    <img :src="icon" alt="" />
  </div>
</template>

<script>
import Icon from './PngIcon/ecl.png'
export default {
  data() {
    return {
      icon: Icon,
    }
  },
}
</script>

<style scoped>
.svg-ete > img {
  width: 10.3px;
  height: 20.6px;
}
</style>
