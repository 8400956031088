<template>
  <div class="svg-ete">
    <img :src="icon" alt="" />
  </div>
</template>

<script>
import Icon from './PngIcon/corbeille.png'
export default {
  data() {
    return {
      icon: Icon,
    }
  },
}
</script>

<style scoped>
.svg-ete > img {
  width: 10px;
  height: 13.6px;
  margin: 0 1px 2.5px 0;
}
</style>
