<template>
  <div>
    <a-space class="select_v" center>
      <a-select
        class="select_v"
        ref="select"
        v-model:value="value1"
        style="width: 120px"
        @change="handleChange"
      >
        <a-select-option value="0.8">20 %</a-select-option>
        <a-select-option value="0.7">30 %</a-select-option>
        <a-select-option value="0.6">40 %</a-select-option>
        <a-select-option value="0.5">50 %</a-select-option>
      </a-select>
    </a-space>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: `${100 - JSON.parse(localStorage.profondeur) * 100} %`,
    }
  },
  methods: {
    handleChange() {
      localStorage.setItem('profondeur', JSON.parse(+this.value1))
      this.$emit('profondeur', +this.value1)
    },
  },
}
</script>

<style>
.select_v > .ant-select-selector {
  border-color: #ffc700 !important;
  padding: 3px 9px !important;
  border-radius: 5px !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-right: 34px !important ;
}
.ant-select-arrow {
  top: 48% !important;
  right: 16px !important;
  width: 12px !important;
}
</style>
