<template>
  <div id="app">
    <!-- <button @click="PdfActive = !PdfActive">active pdf</button> -->
    <div class="btn-to-download" v-if="false">
      <a-button
        type="primary"
        shape="round"
        :size="size"
        class="btnDown"
        @click="
          () => {
            FormShowPdf = true
          }
        "
      >
        <template #icon>
          <DownloadOutlined />
        </template>
      </a-button>
    </div>
    <!-- <h1>Simulateur Off grid</h1> -->
    <transition enter-active-class="fadeIn" leave-active-class=" fadeOut">
      <div class="Zone-content" v-if="ZoneActive">
        <Zone
          class="Zone"
          :ZoneNumber="Zone"
          :keyCity="keyCity"
          :openKeys="openKeys"
          @numberRegion="selectZoneNumber"
          @closeZone="closeZon"
          @dataZon="DataZon"
        />
      </div>
    </transition>

    <a-row class="header_content">
      <a-col class="A_col-2" flex="1 0 500px">
        <div class="gutter-example">
          <a-row :gutter="16" style="align-items: center">
            <a-col align="center" class="gutter-row" :span="8">
              <div class="gutter-box">
                <div class="gutter-box-col">
                  <span class="gutter-box-span">Votre latitude</span>
                  <a-button
                    @click="ZoneActive = !ZoneActive"
                    type="primary"
                    class="btn-Zone"
                    >Zone : {{ Zone }}</a-button
                  >
                </div>
              </div>
            </a-col>

            <a-col align="center" class="gutter-row" :span="8">
              <div class="gutter-box">
                <span class="gutter-box-span">Votre tension</span>
                <select-volt @changVolt="changeV" />
              </div>
            </a-col>

            <a-col align="center" class="gutter-row" :span="8">
              <div class="gutter-box box-Profondeur">
                <span class="gutter-box-span Profondeur"
                  >Profondeur de batterie</span
                >
                <select-kit-puissance @profondeur="profondeurB" />
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>

    <a-row type="flex">
      <a-col class="A_col-1" flex="1 0 50px">
        <image-kits :KitsItem="this.KitsItem" @PuissancePanneau="PuissanceP" />
      </a-col>
    </a-row>

    <a-row type="flex">
      <a-col class="A_col-3" flex="1 0 50px">
        <calculather
          :Wh_C_E="Wh_C_E"
          :Wh_C_H="Wh_C_H"
          :P_O_K="P_O_K"
          :P_B_K="PBK"
          :Width="Width"
          :scaleEnd="scaleEnd"
          :E_ENG="E_ENG"
          :E_WATT="E_WATT"
        />
      </a-col>
    </a-row>

    <a-row>
      <a-col class="A_col-4" flex="1 0 50px" style="margin-bottom: 20px">
        <itemsCon @updateWatt="updateWatt" @updateEng="updateEng" />
      </a-col>
    </a-row>

    <!-- ///////////////////////////////////////////////////////////// -->

    <a-row v-if="FormShowPdf">
      <div class="row-formUserPdf" @click="showPdf = false"></div>
      <div class="formUserPdf">
        <close-circle-outlined
          class="close-icone-pdf"
          @click="FormShowPdf = false"
        />
        <formUserPdf :KitsItem="this.KitsItem" @htlm2Pdf="exportToPDF" />
      </div>
    </a-row>

    <a-row id="compPDF" v-if="PdfActive">
      <vue-pdf
        :KitsItem="this.KitsItem"
        :Wh_CEP="Wh_C_E"
        :Wh_CHP="Wh_C_H"
        :P_OKP="P_O_K"
        :P_BKP="PBK"
        :W_idthP="Width"
        :S_caleEndP="scaleEnd"
        :E_ENGP="E_ENG"
        :E_WATTP="E_WATT"
      />
    </a-row>
  </div>
</template>

<script>
import formUserPdf from './components/ToPdf/formUserPdf.vue'
import axios from 'axios'
import rulerCell from './components/UI/rulerCell.vue'
import Zone from './components/ZoneCell.vue'
import selectVolt from './components/UI/selectVolt.vue'
import selectKitPuissance from './components/UI/selectKitPuissance.vue'
import imageKits from './components/UI/imageKits.vue'
import Calculather from './components/Items/Calculather.vue'
import itemsCon from './components/Items/consumption/itemsCon.vue'
import { DownloadOutlined } from '@ant-design/icons-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import VuePdf from './components/ToPdf/divPDF/VuePdf.vue'
import { convertImg } from './conv/index'
import { nextTick } from 'vue'
import html2pdf from 'html2pdf.js'
export default {
  components: {
    rulerCell,
    Zone,
    selectVolt,
    selectKitPuissance,
    imageKits,
    Calculather,
    itemsCon,
    DownloadOutlined,
    CloseCircleOutlined,
    formUserPdf,
    VuePdf,
  },
  data() {
    return {
      Zone: JSON.parse(localStorage.ZoneNB),
      scaleEnd: 10000,
      ZoneActive: false,
      kits12: [],
      kits220: [],
      kits380: [],
      KitsItem: [],
      Zonedata: {
        id: 1,
        hiver: JSON.parse(localStorage.zoneData).hiver,
        ete: JSON.parse(localStorage.zoneData).ete,
      },
      PPK: 0,
      PBK: 0,
      P_O_K: 0,
      P_B_K: 0,
      Wh_C_E: 0,
      Wh_C_H: 0,
      Width: 0,
      P_B: JSON.parse(localStorage.profondeur),
      E_ENG: 0,
      E_WATT: 0,
      // this is root var for menuZone
      keyCity: JSON.parse(localStorage.zoneData).keyCity,
      openKeys: JSON.parse(localStorage.zoneData).openKeys,
      // data for btn Downloade
      size: 'middle',
      FormShowPdf: false,
      PdfActive: false,
      numberKit: JSON.parse(localStorage.kitNumber),
    }
  },
  beforeCreate() {
    // remove local storage after 8 days
    const _8_days = 8 * 24 * 60 * 60 * 1000
    const now = new Date().getTime()
    const setupTime = localStorage.getItem('setupTime')

    if (setupTime == null) {
      localStorage.setItem('setupTime', now)
    } else {
      if (now - setupTime > _8_days) {
        localStorage.clear()
        localStorage.setItem('setupTime', now)
      }
    }

    ////////////////////////////////////////////////////////////
    // check if the kit number is stored inside the local storage
    if (!localStorage.kitNumber) {
      localStorage.setItem('kitNumber', JSON.stringify(0))
    }
    // check if the kit Item is stored inside the local storage
    if (!localStorage.KitsItem) {
      localStorage.setItem('KitsItem', JSON.stringify([]))
    }
    // check if the tension Item is stored inside the local storage
    if (!localStorage.tension) {
      localStorage.setItem('tension', JSON.stringify(220))
    }
    // check if the profondeur Item is stored inside the local storage
    if (!localStorage.profondeur) {
      localStorage.setItem('profondeur', JSON.stringify(0.8))
    }
    // check if the Zone Number is stored inside the local storage
    if (!localStorage.ZoneNB) {
      localStorage.setItem('ZoneNB', JSON.stringify(1))
    }
    // check if the zoneData Item is stored inside the local storage
    if (!localStorage.zoneData) {
      localStorage.setItem(
        'zoneData',
        JSON.stringify({
          ete: 7,
          hiver: 3,
          keyCity: 1,
          openKeys: ['sub1'],
        })
      )
      localStorage.ZoneNB = 1
    }

    /////////////////////////////////////////////
    // get data from the server befre create
    axios
      .get(
        `https://cptechmaroc.ma/wp-json/wc/v3/products?category=211&per_page=100`,
        {
          params: {
            consumer_key: 'ck_071c45e05438bb6f599b80969990ca6357510bdc',
            consumer_secret: 'cs_2554ed364f37e4e143b48197377e50dabfe5f19e',
          },
        }
      )
      .then(({ data }) => {
        // Sorting  the data by  power
        const vl = data.sort(
          (a, b) => +a.attributes[1].options[0] - +b.attributes[1].options[0]
        )

        vl.forEach((vl) => {
          const aptions = vl.attributes[3].options

          if (aptions.length === 2) {
            if (+aptions[1] === 12) {
              return this.kits12.push(vl)
            }
            if (+aptions[1] === 220) {
              return this.kits220.push(vl)
            }
            if (+aptions[1] === 380) {
              this.kits380.push(vl)
            }
          } else if (aptions.length === 1) {
            if (+aptions[0] === 12) {
              return this.kits12.push(vl)
            }
            if (+aptions[0] === 220) {
              return this.kits220.push(vl)
            }
            if (+aptions[0] === 380) {
              this.kits380.push(vl)
            }
          }
        })

        if (JSON.parse(localStorage.tension) === 220) {
          this.KitsItem = this.kits220
        } else if (JSON.parse(localStorage.tension) === 380) {
          this.KitsItem = this.kits380
        } else if (JSON.parse(localStorage.tension) === 12) {
          this.KitsItem = this.kits12
        }

        this.PPK = +this.KitsItem[0].attributes[1].options[0]
        this.P_O_K = +this.KitsItem[0].attributes[0].options[0]
        this.P_B_K = +this.KitsItem[0].attributes[2].options[0]
        this.calculPuissance()
      })
      .catch((err) => {
        return err
      })
  },
  created() {
    window.addEventListener('resize', this.calculPuissance)
    window.addEventListener('beforeunload', this.leaving)
  },
  methods: {
    selectZoneNumber(vl) {
      this.Zone = vl
      localStorage.setItem('ZoneNB', JSON.stringify(vl))
    },
    closeZon(vl) {
      this.ZoneActive = vl
    },

    PuissanceP(vl) {
      this.PPK = vl.panneau
      this.P_O_K = vl.onduleur
      this.P_B_K = vl.battre

      this.calculPuissance()
    },

    changeV(vl) {
      if (vl === 380) {
        this.changeVDATA(this.kits380)
      }
      if (vl === 220) {
        this.changeVDATA(this.kits220)
      }
      if (vl === 12) {
        this.changeVDATA(this.kits12)
      }
      localStorage.kitNumber = 0
      this.calculPuissance()
    },

    changeVDATA(ELE) {
      this.KitsItem = ELE
      localStorage.setItem('KitsItem', JSON.stringify(this.KitsItem))
      this.PPK = +ELE[0].attributes[1].options[0]
      this.P_O_K = +ELE[0].attributes[0].options[0]
      this.P_B_K = +ELE[0].attributes[2].options[0]
    },
    profondeurB(vl) {
      this.P_B = vl
      this.calculPuissance()
    },

    calculPuissance() {
      this.Width = window.document.querySelector('#rulerCell').scrollWidth
      this.Wh_C_E = this.PPK * 0.8 * this.Zonedata.ete
      this.Wh_C_H = this.PPK * 0.8 * this.Zonedata.hiver
      this.PBK = this.P_B_K * this.P_B

      this.checkScaleEnd()
    },

    DataZon(vl) {
      this.Zonedata.ete = vl.dataZon.pes_ete
      this.Zonedata.hiver = vl.dataZon.pes_hiver

      this.keyCity = vl.keyVl
      this.openKeys[0] = vl.openKey[0]

      localStorage.setItem(
        'zoneData',
        JSON.stringify({
          ete: this.Zonedata.ete,
          hiver: this.Zonedata.hiver,
          keyCity: this.keyCity,
          openKeys: this.openKeys,
        })
      )

      this.calculPuissance()
    },
    checkScaleEnd() {
      if (this.Wh_C_E < 8000) {
        return (this.scaleEnd = 10000)
      }
      if (this.Wh_C_E < 15000) {
        return (this.scaleEnd = 25000)
      }
      if (this.Wh_C_E < 40000) {
        return (this.scaleEnd = 60000)
      }
    },
    updateEng(vl) {
      this.E_ENG = vl
    },
    updateWatt(vl) {
      this.E_WATT = vl
    },
    // async leaving() {
    //   const El_kits = this.KitsItem[localStorage.kitNumber]

    //   let data = {
    //     id: localStorage.setupTime,
    //     date: new Date(),
    //     kit_name: El_kits.name,
    //     kit_permalink: El_kits.permalink,
    //     consonmmation_J: this.E_ENG,
    //     I_Watts: this.E_WATT,
    //     profondeur_B: `${100 - JSON.parse(localStorage.profondeur) * 100} %`,
    //     tension_v: localStorage.tension,
    //     NumberZone: localStorage.ZoneNB,
    //     listItems: JSON.parse(localStorage.listItems),
    //   }

    //   const config = {
    //     method: 'post',
    //     url: 'http://localhost:3000/api/v1/simulateur-cp-tech-off-grid/data-clinet',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     data: data,
    //   }
    //   await axios(config)
    //     .then(function (response) {
    //       console.log(JSON.stringify(response.data))
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     })
    // },

    async exportToPDF(vl) {
      this.PdfActive = true
      await nextTick()
      // convert to base64
      await this.convImg()
      //  document.getElementById
      let content = document.getElementById('element-to-print')

      html2pdf(content, {
        margin: 0.4,
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          useCORS: true,
          scale: 4,
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
        },
      })

      this.FormShowPdf = vl
      this.PdfActive = vl
    },
    async convImg() {
      const origin = window.location.origin
      const Imag = document.querySelector('#WbImag')
      //
      let imggg = `${this.KitsItem[this.numberKit].images[0].src}`
      let urlImag = imggg.replace('https://cptechmaroc.ma/', `${origin}/`)
      // downlode the image
      const vl = await new convertImg(urlImag).conv()
      Imag.src = vl
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap');
#app {
  /* padding: 0 5px; */
}
body {
  font-family: 'Poppins', sans-serif !important;
}
h1 {
  text-align: center;
}
/* .A_col-1 {
} */
.A_col-2 {
  background-color: #e22623;
  padding: 15px 15px;
  border-radius: 10px 10px 0 0;
}
.A_col-3 {
  color: black;
}
.gutter-example :deep(.ant-row > div) {
  background: transparent;
  border: 0;
}
.gutter-example :deep(.ant-row) {
  display: flex;
  flex-direction: row;
  align-items: center !important;
}
.gutter-box {
  color: white;
  padding: 5px 0;
}
.gutter-box-span {
  /* text-align: left; */
  color: #fff;
  font-weight: 500;
  font-size: 13.1297px;
}
.gutter-box-col {
  width: 120px;
  display: flex;
  flex-direction: column;
}
.btn-Zone {
  color: #000 !important;
  background-color: #ffc700 !important;
  border-color: #ffc700 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}
.btn-Zone:hover {
  background-color: #ffc700 !important;
  border-color: #ffc700 !important;
  color: #e22623 !important;
}
.Zone-content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background: rgba(45, 45, 45, 0.77);
  backdrop-filter: blur(7.5px);
}
.Zone {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  background: #f5f5f5;
  border: 0.916182px solid #e6e6e6;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.4);
  border-radius: 8.24564px;
  width: 700px;
  height: 600.1px;
  padding: 30px;
}

.A_col-4 {
  margin-top: 20px;
}

.btn-to-download {
  position: relative;
  /* width: 20%; */
}
.ant-btn-round {
  position: fixed !important;
  top: 10px !important;
  right: 10px !important;
  z-index: 300 !important;
  background-color: #ffc700 !important;
  border-color: #ffc700 !important;
  height: 53px !important;
  width: 53px !important;
}
/* .btnDown {
} */

.formUserPdf {
  position: fixed;
  z-index: 500;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.row-formUserPdf {
  position: fixed;
  z-index: 400;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.732);
}
.close-icone-pdf {
  position: fixed;
  z-index: 400;
  top: -22px !important;
  right: -22px !important;
  color: #ffc700 !important;
  font-size: 30px;
}
#compPDF {
  overflow-y: scroll;
  overflow-x: scroll;
  height: 0px;
}

/* ////////////////////////////////////////////////////// */

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    /* transform: scale(1); */
  }
  to {
    opacity: 0;
    /* transform: scale(0.5); */
  }
}

/* ///////////////////////////style scrollbare ////////////////////////////////*/
body::-webkit-scrollbar {
  width: 11px;
  scrollbar-width: thin;
  scrollbar-color: #e6e6e6;
}
body::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 0 7px 7px 0;
}
body::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 6px;
  border: 2px solid #d7d7d7;
  box-shadow: 0px 0px 2.5px #e2262346 set 0px 0px 4px #f6f8f980;
}
/*////////////////////////// media ////////////////////////////////////////*/
@media screen and (max-width: 745px) {
  .Zone {
    transform: translate(-50%, -50%) scale(0.85);
  }
}

@media screen and (max-width: 665px) {
  .Zone {
    transform: translate(-50%, -50%) scale(0.75);
  }
}
@media screen and (max-width: 575px) {
  .Zone {
    transform: translate(-50%, -50%) scale(0.65);
  }
}
@media screen and (max-width: 600px) {
  body {
    font-size: 13px !important;
  }
  #app {
    padding: 0px 2px;
  }
  .gutter-box-col {
    width: 85px;
    display: flex;
    flex-direction: column;
  }
  .gutter-box-span {
    color: #fff;
    font-weight: 500;
    font-size: 10.1297px;
  }
  .btn-Zone span {
    font-size: 11.5px;
    color: #000;
  }
  .box-Profondeur {
    margin-top: 0px;
  }
  .ant-select {
    width: 89px !important;
    font-size: 11.5px !important;
  }
  .select_v > .ant-select-selector {
    padding: 0px 8px !important;
  }
  .A_col-2 {
    background-color: #e22623;
    padding: 19px 15px;
    border-radius: 10px 10px 0 0;
  }
}
@media screen and (max-width: 471px) {
  body {
    font-size: 12.5px !important;
  }
  /* .box-Profondeur {
    margin-top: -17px;
  } */
}
@media screen and (max-width: 415px) {
  body {
    font-size: 12.5px !important;
  }
  .box-Profondeur {
    margin-top: -17px;
  }
}
</style>
