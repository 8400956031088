<template>
  <div class="svg-ete">
    <img :src="battre" class="svg-ete" />
  </div>
</template>

<script>
import Battre from './PngIcon/battre.png'
export default {
  data() {
    return {
      battre: Battre,
    }
  },
}
</script>

<style scoped>
.svg-ete {
  height: 31px;
  width: 42px;
}
</style>
