<template>
  <div class="main_S">
    <div
      class="listItem-row1"
      style="gap: 18px; height: 23px; margin-bottom: 12px"
    >
      <h4 style="margin: 0px; font-size: 13px">Éclairage</h4>
      <ecl />
      <span
        style="
          background: rgb(226, 38, 35);
          border-radius: 2.39637px;
          padding: 2px 4px;
          color: rgb(255, 255, 255);
          width: 69px;
          font-size: 11px;
        "
      >
        {{ consommation }} Wh/j
      </span>
    </div>
    <div class="listItem-row2">
      <div class="image">
        <img :src="image" />
      </div>

      <div class="calcul">
        <div class="calcul-row">
          <label>Quantité :</label>
          <button @click="Count('decr')">-</button>
          <input
            @keypress="isNumber($event)"
            type="number"
            v-model="this.itemCount"
            @input="Count('chang')"
          />

          <button @click="Count('incr')">+</button>
        </div>

        <div style="margin: 0.5rem 0" class="calcul-row">
          <label>Utilisation <span style="font-size: 9px">(H/j)</span> :</label>
          <button @click="Hours('decr')">-</button>
          <input
            @keypress="isNumber($event)"
            type="number"
            v-model="this.itemHours"
            @input="Hours('chang')"
          />

          <button @click="Hours('incr')">+</button>
        </div>
      </div>
    </div>

    <div
      class="calcul-row2"
      style="idth: 316px; justify-content: flex-end; gap: 48px; margin-top: 5px"
    >
      <!-- <button @click="Energy('decr')">-</button> -->
      <div class="calcul-row2 v_row2">
        <input
          @keypress="isNumber($event)"
          type="number"
          v-model="this.itemEnergy"
          @input="Energy('chang')"
        />
        <label style="font-size: 12px">Watt</label>
      </div>
      <!-- <button @click="Energy('incr')">+</button> -->

      <div class="d-btn">
        <button class="btn" @click="remove(id)">
          <corbeille />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Corbeille from '@/components/icon/corbeille.vue'
import ecl from '../../icon/ecl.vue'
export default {
  props: ['id', 'hours', 'energy', 'count', 'image', 'name', 'idImag'],
  emits: ['updateCon', 'remove'],
  components: {
    ecl,
    Corbeille,
  },
  data() {
    return {
      consommation: null,
      itemCount: 0,
      itemEnergy: 0,
      itemHours: 0,
    }
  },
  async created() {
    this.itemCount = this.count
    this.itemEnergy = this.energy
    this.itemHours = this.hours

    this.consommation = await (this.count * this.energy * this.hours)
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    // ////////////////
    Count(vl) {
      if (vl === 'incr') {
        this.itemCount += 1
      } else if (vl === 'decr') {
        this.itemCount -= 1
        if (this.itemCount === 0) {
          this.itemCount = 1
        }
      } else if (vl === 'chang') {
        this.calculCon()
      }
      this.calculCon()
    },
    Hours(vl) {
      if (vl === 'incr') {
        this.itemHours += 1
      } else if (vl === 'decr') {
        this.itemHours -= 1
        if (this.itemHours === 0) {
          this.itemHours = 1
        }
      } else if (vl === 'chang') {
        this.calculCon()
      }
      this.calculCon()
    },
    Energy(vl) {
      if (vl === 'incr') {
        this.itemEnergy += 1
      } else if (vl === 'decr') {
        this.itemEnergy -= 1
        if (this.itemEnergy === 0) {
          this.itemEnergy = 1
        }
      } else if (vl === 'chang') {
        this.calculCon()
      }

      this.calculCon()
    },
    calculCon() {
      this.consommation = this.itemCount * this.itemEnergy * this.itemHours
      const dataUpdate = {
        id: this.id,
        count: this.itemCount,
        hours: this.itemHours,
        energy: this.itemEnergy,
        image: this.image,
        name: this.name,
        idImag: this.idImag,
      }

      this.$emit('updateCon', dataUpdate)
    },
    remove(vl) {
      this.$emit('remove', vl)
    },
    removeAll(vl) {
      this.$emit('removeAll')
    },
  },
}
</script>

<style scoped>
.image > img {
  width: 61.88px;
  height: 61.88px;
  padding: 0.5rem;
  border-radius: 50%;
  background: #ffffff;
  border: 1.47368px solid #ececec;
  box-shadow: 0px 3px 1.68971px rgb(0 0 0 / 5%);
}
.listItem-row1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.listItem-row2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 17px;
}
.calcul {
  width: 171px;
}
.calcul-row,
.calcul-row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.calcul-row2 input {
  width: 39px;
  text-align: center;
  border: none;
  font-size: 12px;
}

.calcul-row2 input::-webkit-outer-spin-button,
.calcul-row2 input::-webkit-inner-spin-button,
.calcul-row input::-webkit-outer-spin-button,
.calcul-row input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.calcul-row2 label {
  width: 53px;
  color: #cfcfcf;
}
.v_row2 {
  border: 1.5px solid #cfcfcf;
  border-radius: 4px;
}

.calcul-row label {
  width: 112px;
  text-align: left;
  color: #616161;
  font-size: 12px;
}
.calcul-row input {
  width: 30px;
  text-align: center;
  border: none;
  font-size: 12px;
  font-weight: 500;
}
.calcul-row input:focus,
.calcul-row2 input:focus {
  outline: none;
}
.calcul-row button {
  width: 20.42px;
  height: 22.42px;
  background: #ffb800;
  border-radius: 2.39637px;
  border: none;
  cursor: pointer;
}
label {
  width: 5rem;
}
.btn {
  width: 24.96px;
  height: 24.96px;
  background-color: #e22623;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
