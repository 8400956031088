<template>
  <main>
    <div class="ZonHeader" v-if="withScreen > 500">
      <h2>
        <span style="color: #e22623; font-weight: 500">votre sélection: </span>
        zone
        {{ ZoneNumber }}
      </h2>
    </div>

    <div class="zoneNAme">
      <div class="custom-slick-arrow" @click="closeZone(false)">
        <CloseOutlined style="border-radius: 5px" />
      </div>

      <div class="MenuZon">
        <menu-zone
          @updateZon="updateZon"
          :keyVl="this.keyCity"
          :OpenKeys="this.openKeys"
        />
      </div>
      <div class="svg-container" v-if="withScreen > 500">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="-12 0 973.7 1000"
          style="enable-background: new -12 0 973.7 1000"
          xml:space="preserve"
        >
          <path
            :class="{ heavy: ZoneNumber === 4, st0: ZoneNumber !== 4 }"
            @click="selectZone(4)"
            dataZone="4"
            class="st0"
            d="M677.2,376.4c0.2,0.3,0.3,0.7,0.2,1c0,0.5-0.1,0.8-0.2,1.1c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.1-0.6,0.2-0.9,0.2
	c-0.3,0-0.6,0-0.9-0.2c-0.2-0.2-0.4-0.4-0.6-0.7c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.4,0-0.7,0.2-1c0.1-0.3,0.3-0.5,0.6-0.7
	c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6,0,0.9,0.2C676.8,375.9,677,376.1,677.2,376.4z M449.6,642.7c0-0.4-0.1-0.6-0.4-0.8
	c-0.2-0.2-0.6-0.3-1-0.3h-1.6v2.3h1.7c0.4-0.2,0.7-0.2,1-0.4C449.5,643.3,449.6,643,449.6,642.7z M668.5,375.7
	c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1
	c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1
	c0-0.3-0.1-0.7-0.2-1C668.9,376.1,668.7,375.9,668.5,375.7z M471.6,568.4c0.3-0.2,0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.6,0.2-1
	c0-0.5-0.1-0.9-0.2-1.1c-0.2-0.3-0.4-0.5-0.6-0.7c-0.3-0.2-0.6-0.2-0.9-0.2s-0.6,0.1-0.9,0.2s-0.5,0.4-0.6,0.7
	c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.8,0.2,1.1c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2S471.3,568.5,471.6,568.4z M495.8,565
	c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1,0.4s-0.5,0.6-0.5,1h3.1C496.3,565.6,496.1,565.2,495.8,565z M666.3,342.8
	c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1,0.4s-0.5,0.6-0.5,1h3.1C666.8,343.4,666.6,343,666.3,342.8z M697.3,375.7
	c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1
	c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1
	c0-0.3-0.1-0.7-0.2-1C697.7,376.1,697.5,375.9,697.3,375.7z M683.7,375.4c-0.5,0-0.9,0.2-1.2,0.5s-0.5,0.8-0.5,1.5
	c0,0.6,0.2,1.1,0.5,1.5c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.6,0,0.9-0.2c0.3-0.1,0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.7,0.2-1.1
	c0-0.6-0.2-1.1-0.5-1.5C684.6,375.6,684.2,375.4,683.7,375.4z M449,639.9c0.2-0.2,0.3-0.4,0.3-0.8s-0.1-0.6-0.3-0.8
	s-0.5-0.3-0.9-0.3h-1.5v2.2h1.5C448.5,640.2,448.8,640.1,449,639.9z M718.9,316.3c-0.5,0-0.9,0.2-1.2,0.5s-0.5,0.8-0.5,1.5
	c0,0.6,0.2,1.1,0.5,1.5c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.7-0.1,0.9-0.2c0.3-0.1,0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.7,0.2-1.1
	c0-0.6-0.2-1.1-0.5-1.5C719.8,316.5,719.4,316.3,718.9,316.3z M539.1,415.8c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2
	c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2
	c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1c0-0.3-0.1-0.7-0.2-1C539.5,416.2,539.3,416,539.1,415.8z
	M473.2,640c-0.3-0.2-0.6-0.2-0.9-0.2s-0.6,0.1-0.9,0.2s-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.8,0.2,1
	c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1
	c0-0.2-0.1-0.6-0.2-0.9C473.6,640.4,473.4,640.2,473.2,640z M651.7,346.2c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2
	c0.3-0.2,0.5-0.4,0.6-0.7s0.2-0.6,0.2-1.1c0.1-0.3,0-0.7-0.2-1s-0.4-0.5-0.6-0.7c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2
	c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1C651.3,345.8,651.5,346,651.7,346.2z M620.8,342.7
	c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1
	c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1
	c0.1-0.3,0-0.7-0.2-1S621,342.9,620.8,342.7z M604.7,341c-0.4-0.3-0.8-0.3-1.3-0.3s-1,0.1-1.3,0.3s-0.7,0.5-0.9,1
	c-0.2,0.4-0.3,0.9-0.3,1.5c0,0.6,0.1,1.1,0.3,1.5c0.2,0.4,0.5,0.8,0.9,1s0.8,0.3,1.3,0.3s0.9,0,1.3-0.3c0.4-0.2,0.7-0.6,0.9-1
	c0.2-0.4,0.3-0.9,0.3-1.5c0-0.6-0.1-1.1-0.3-1.5C605.4,341.6,605.1,341.3,604.7,341z M488,564.8c-0.2-0.2-0.6-0.2-0.9-0.2
	s-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.7,0.2,1c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2
	s0.6-0.1,0.9-0.2s0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.6,0.2-1.1c0.1-0.2,0-0.6-0.2-0.9C488.4,565.2,488.2,565,488,564.8z M491.5,639.7
	c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.6,0.2,1.1,0.5,1.5c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0.1,0.6,0,0.9-0.2
	c0.3-0.1,0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.7,0.2-1.1c0-0.6-0.2-1.1-0.5-1.5C492.4,639.9,492,639.7,491.5,639.7z M639.6,342.7
	c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1
	c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1
	c0-0.3,0-0.7-0.2-1S639.8,342.9,639.6,342.7z M834.6,244.4c0.2-0.2,0.3-0.4,0.3-0.8c0-0.4-0.1-0.6-0.3-0.8s-0.5-0.3-0.9-0.3h-1.5
	v2.2h1.5C834.1,244.7,834.4,244.6,834.6,244.4z M834.8,246.4c-0.2-0.2-0.6-0.3-1-0.3h-1.7v2.3h1.7c0.5-0.1,0.8-0.2,1.1-0.4
	c0.2-0.2,0.3-0.5,0.3-0.8C835.2,246.8,835.1,246.6,834.8,246.4z M735.6,316.6c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2
	c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2
	c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7s0.2-0.6,0.2-1.1c0-0.4-0.1-0.7-0.2-1C736,317,735.8,316.8,735.6,316.6z
	M857.8,244.6c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1
	c0,0.4,0.1,0.8,0.2,1.1c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7
	c0.1-0.3,0.2-0.6,0.2-1.1c0-0.4,0-0.7-0.2-1C858.2,245,858,244.8,857.8,244.6z M841.2,244.3c-0.5,0-0.9,0.2-1.2,0.5
	s-0.5,0.8-0.5,1.5c0,0.6,0.2,1.1,0.5,1.5c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.4,0.6-0.7
	c0.2-0.3,0.2-0.7,0.2-1.1c0-0.6-0.2-1.1-0.5-1.5C842.1,244.5,841.7,244.3,841.2,244.3z M874.9,244.6c-0.3-0.2-0.6-0.2-0.9-0.2
	c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1c0.2,0.3,0.4,0.5,0.6,0.7
	c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1c0.1-0.4,0-0.7-0.2-1
	S875.1,244.8,874.9,244.6z M933.9,270.8c-10.6,0-20.5,2-29.1-0.4c-24.8-6.7-49.2-1.7-73.7,0c-1.4,0.1-3.8,4.8-3.4,6.9
	c0.8,4.6,3.2,8.9,5.4,14.6c-15.1,3.3-28.9,6.5-42.8,9.3c-5.5,1.1-8.5,3-7.8,9c0.6,5.1,0.9,9.7-4.8,13.6c-2.3,1.6-1.2,7.9,0.7,10.4
	c3.4,2.7,7.4,5,10,8.4c1.3,1.8,0.4,5.5-0.1,8.3c-0.8,4.1-1.9,8.1-3.4,12c-0.7,1.8-2.3,3.7-4,4.4c-5.5,2.2-11.2,5.3-17,5.5
	c-13.4,0.5-23.2,6.3-32,15.5c-4.2,4.4-10.1,7.1-15.3,10.5c-9.6,6.4-20.2,11.8-28.5,19.6c-6.5,6.1-9.8,15.3-14.9,22.8
	c-5.3,7.7-8.4,7.5-15.4,0.4c-1.4-1.4-4.4-2.3-6.4-2c-15.2,1.6-30.7,2.4-45.6,5.8c-10.5,2.4-14.7,3.4-24.8,6.1
	c-2.9,0.8-9.2,4-10.2,4.5c-15.2,7.6-17,10.9-31.6,19.6c-12.4,7.4-30.5,6.7-30.6,23.5c-0.2,27.3-1.8,62.4,0,87.7
	c1.8,25.1,1.6,56.7,1.5,82c0,1.3-0.6,1.3-0.6-0.1c-22.2,0-46.6-0.3-71.6-0.7v-54.4c12-54.2,39.4-137.5,98.7-260.6
	c78.3-73.1,294.9-167.9,364.4-197.3c0,1.9,0.1,3.8,0.1,5.8c5.6,2.7,7.8,8.4,4.2,15.7c-4.9,9.9-0.7,17.4,5.6,25
	c1.7,2.1,0.7,6.2,2,8.9c3,6,5.7,12.7,10.3,17.4c5.3,5.4,12.6,8.9,19.8,13.8C935.7,250.3,919.8,255.1,933.9,270.8z M493.2,561.5
	l2.8,1.3v-1.2l-2.8-1.5V561.5z M491.4,566.6c0,0.7,0.1,1.3,0.4,1.8c0.3,0.5,0.7,0.9,1.2,1.2c0.5,0.3,1.1,0.4,1.7,0.4
	c0.8,0,1.5-0.2,2-0.6c0.5-0.4,0.9-1,1.1-1.6H496c-0.2,0.5-0.7,0.8-1.3,0.8c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.5-0.6-0.5-1.1h4.9
	c0-0.2,0-0.4,0-0.6c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.7-0.9-1.2-1.2c-0.5-0.3-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4
	s-0.9,0.7-1.2,1.2S491.4,565.9,491.4,566.6z M475.6,569.9h1.7v-3.7c0-0.5,0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.4,1.1-0.4s0.8,0.1,1.1,0.4
	s0.4,0.7,0.4,1.2v3.7h1.7V566c0-0.6-0.1-1-0.3-1.5c-0.2-0.5-0.5-0.8-0.9-1c-0.4-0.2-0.8-0.3-1.4-0.3c-0.4,0-0.8,0-1.2,0.2
	c-0.4,0.2-0.7,0.4-0.9,0.7V561h-1.7h0V569.9z M477.5,576.9c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9
	S480.2,576.9,477.5,576.9z M452.5,636.7c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7
	s-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3C452.6,636.2,452.5,636.4,452.5,636.7z M452.6,638.5v6.6h1.7v-6.6H452.6z
	M445,645.1h3.6c0.6,0,1.1-0.1,1.5-0.3c0.5-0.2,0.8-0.5,1-0.8c0.2-0.4,0.3-0.8,0.3-1.2c0-0.5-0.2-0.9-0.5-1.3
	c-0.4-0.4-0.7-0.6-1.1-0.7c0.4-0.1,0.7-0.4,1-0.7s0.4-0.7,0.4-1.2c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.4-0.5-0.6-0.9-0.8
	c-0.4-0.2-0.9-0.3-1.5-0.3H445V645.1z M457.4,660.8c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9
	S457.4,663.5,457.4,660.8z M459.7,638.4c-0.5,0-0.9,0.1-1.2,0.3s-0.6,0.4-0.8,0.9v-1H456v6.6h1.7v-3.3c0-0.6,0.1-1.1,0.4-1.3
	c0.3-0.3,0.7-0.4,1.2-0.4h0.4V638.4z M462.1,569.9l2.2-5.4v5.4h1.7v-8.4h-1.9l-2.7,6.2l-2.7-6.2h-1.9v8.4h1.7v-5.4l2.3,5.4H462.1z
	M468.2,643.8h-2.7v-7.1h-1.7v8.4h4.4V643.8z M468.7,569.7c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8
	v1h1.7v-6.6h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2s-0.4,1.1-0.4,1.9
	c0,0.7,0.1,1.3,0.4,1.8S468.3,569.4,468.7,569.7z M475.7,638.6H474v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3
	s-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2s-0.4,1.1-0.4,1.9c0,0.7,0.1,1.3,0.4,1.8c0.3,0.5,0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4
	c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8v1h1.7V638.6z M483.6,641.2c0-0.6-0.1-1.1-0.3-1.5c-0.2-0.5-0.5-0.8-0.9-1
	c-0.4-0.2-0.8-0.3-1.4-0.3c-0.4,0-0.8,0-1.2,0.2c-0.4,0.2-0.7,0.4-0.9,0.7v-3.1h-1.7v8.9h1.7v-3.7c0-0.5,0.1-0.9,0.4-1.2
	c0.3-0.3,0.6-0.4,1.1-0.4s0.8,0.1,1.1,0.4s0.4,0.7,0.4,1.2v3.7h1.7V641.2z M486.9,636.2h-1.7v8.9h1.7V636.2z M487.6,570
	c0.5,0,1-0.1,1.5-0.4s0.8-0.7,1.1-1.2s0.4-1.1,0.4-1.8s-0.1-1.3-0.4-1.8c-0.3-0.5-0.7-0.9-1.1-1.2c-0.4-0.3-0.9-0.4-1.5-0.4
	c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.4-0.9,0.6v-3.2h-1.7v8.9h1.7v-0.9c0.2,0.4,0.5,0.6,0.9,0.8C486.7,569.9,487.1,570,487.6,570
	z M495,641.8c0-0.7-0.2-1.3-0.5-1.8c-0.3-0.5-0.7-0.9-1.2-1.2c-0.5-0.3-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.2
	c-0.4,0.5-0.5,1.1-0.5,1.8s0.1,1.3,0.4,1.8c0.3,0.5,0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4c0.7,0,1.3-0.1,1.8-0.4s0.9-0.7,1.2-1.2
	S495,642.5,495,641.8z M502.5,638.5H501v3.7c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1.1,0.4c-0.5,0-0.8-0.1-1.1-0.4
	s-0.4-0.7-0.4-1.2v-3.7h-1.7v3.9c0,0.6,0.1,1.1,0.3,1.5c0.2,0.5,0.5,0.8,0.9,1c0.4,0.2,0.9,0.3,1.4,0.3c0.4,0,0.8,0,1.1-0.2
	s0.6-0.4,0.8-0.7v0.8h1.7h0V638.5z M504.7,568.1c-0.1-0.5-0.2-0.8-0.4-1.1s-0.5-0.5-0.8-0.6c-0.3-0.2-0.7-0.3-1.2-0.4
	c-0.4-0.2-0.8-0.3-1-0.4s-0.3-0.3-0.3-0.5s0-0.4,0.2-0.5s0.4-0.2,0.7-0.2s0.6,0,0.8,0.2c0.2,0.1,0.3,0.3,0.3,0.6h1.6
	c-0.1-0.7-0.3-1.2-0.8-1.6c-0.5-0.4-1.1-0.6-1.9-0.6c-0.5,0-1,0.1-1.4,0.3s-0.7,0.4-0.9,0.7c-0.2,0.3-0.3,0.6-0.3,1
	c0,0.5,0.1,0.8,0.3,1.1c0.2,0.3,0.5,0.5,0.8,0.6c0.3,0.2,0.7,0.3,1.2,0.4c0.4,0.2,0.8,0.3,1,0.4s0.3,0.3,0.3,0.5s-0.1,0.4-0.3,0.5
	c-0.2,0.1-0.5,0.2-0.8,0.2s-0.6-0.2-0.8-0.3s-0.4-0.3-0.4-0.6h-1.7c0,0.4,0.2,0.8,0.4,1.1s0.6,0.6,1,0.8s0.9,0.3,1.8,0.4
	c0.5,0,1-0.1,1.4-0.3s0.7-0.4,0.9-0.7C504.6,568.8,504.7,568.5,504.7,568.1z M528.4,425.8c0-2.7-2.2-4.9-4.9-4.9s-4.9,2.2-4.9,4.9
	s2.2,4.9,4.9,4.9S528.4,428.5,528.4,425.8z M533.9,412.5h-6.2v1.4h2.2v7h1.7v-7h2.3V412.5z M541.6,414.4h-1.7v0.9
	c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2s-0.4,1.1-0.4,1.9c0,0.7,0.1,1.3,0.4,1.8
	s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8v1h1.7V414.4z M546.7,414.1h-1.5v-1.6h-1.7
	v1.6h-0.8v1.4h0.8v3.2c0,1.4,0.7,2.1,2.1,2.1h1.1v-1.4h-0.8c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2v-0.1h1.5h0V414.1z
	M554.4,414.4h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2s-0.4,1.1-0.4,1.9
	c0,0.7,0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8v1h1.7V414.4z
	M649.1,344.5c0,0.7,0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.8v1h1.7v-6.6
	h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1.1,1.2
	C649.3,343.2,649.1,343.8,649.1,344.5z M594.4,347.8c0-2.7-2.2-4.9-4.9-4.9s-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9
	S594.4,350.5,594.4,347.8z M607.7,343.6c0-0.8-0.2-1.5-0.6-2.2c-0.3-0.6-0.8-1.1-1.5-1.5c-0.7-0.4-1.4-0.6-2.2-0.6s-1.5,0.2-2.2,0.6
	c-0.7,0.3-1.2,0.8-1.6,1.5c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.5,0.6,2.2c0.4,0.6,0.9,1.1,1.6,1.5c0.7,0.4,1.3,0.6,2.1,0.6
	s1.5-0.2,2.2-0.6c0.7-0.3,1.2-0.8,1.6-1.5C607.5,345.2,607.7,344.4,607.7,343.6z M615.2,341.1h-1.5v3.7c0,0.5-0.1,0.9-0.4,1.2
	s-0.6,0.4-1.1,0.4s-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.7-0.4-1.2v-3.7H609v3.9c0,0.6,0.1,1.1,0.3,1.5c0.2,0.5,0.5,0.8,0.9,1
	c0.4,0.2,0.9,0.3,1.4,0.3c0.4,0,0.8,0,1.1-0.2s0.6-0.4,0.8-0.7v0.8h1.7V341.1z M623.3,341.2h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8
	c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1.1,1.2c-0.2,0.6-0.4,1.2-0.4,1.9s0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2
	c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.8v1h1.7V341.2z M628.7,341c-0.5,0-0.9,0.1-1.2,0.3s-0.6,0.5-0.8,0.9
	v-1H625v6.6h1.7v-3.3c0-0.6,0.1-1.1,0.4-1.3c0.3-0.3,0.7-0.4,1.2-0.4h0.4V341z M634.4,346.4h-3l2.9-3.9v-1.4h-4.8v1.4h2.9l-2.9,3.9
	v1.4h4.9V346.4z M642.1,341.2h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1.1,1.2
	c-0.3,0.6-0.4,1.2-0.4,1.9s0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.8v1h1.7
	V341.2z M643.4,347.8h4.9v-1.4h-3l2.9-3.9v-1.4h-4.8v1.4h2.9l-2.9,3.9h0V347.8z M656.4,387.8c0-2.7-2.2-4.9-4.9-4.9
	s-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9S656.4,390.5,656.4,387.8z M657.2,342.4h0.8v3.2c0,1.4,0.7,2.1,2.1,2.1h1.1v-1.4h-0.8
	c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2v-0.1h1.5V341h-1.5v-1.6H658v1.6h-0.8V342.4z M663.1,372.4h-5.7v1.4h3.7
	l-3.7,5.7v1.3h5.7v-1.4h-3.8l3.8-5.7V372.4z M663.5,347.5c0.5,0.3,1.1,0.4,1.7,0.4c0.8,0,1.5-0.2,2-0.6c0.5-0.4,0.9-1,1.1-1.6h-1.8
	c-0.2,0.5-0.7,0.8-1.3,0.8c-0.4,0-0.8-0.2-1.1-0.4s-0.5-0.6-0.5-1.1h4.9c0-0.2,0-0.4,0-0.6c0-0.6-0.1-1.2-0.4-1.7s-0.7-0.9-1.2-1.2
	s-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.2c-0.3,0.5-0.4,1.1-0.4,1.8c0,0.7,0.1,1.3,0.4,1.8
	S663,347.2,663.5,347.5z M671,374.2h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4
	c-0.5,0.3-0.8,0.7-1.1,1.2c-0.3,0.6-0.4,1.2-0.4,1.9s0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4
	c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.8v1h1.7V374.2z M679.2,374.2h-1.7v0.9c-0.2-0.4-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.1,0
	c-0.5,0-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2c-0.3,0.5-0.4,1.1-0.4,1.8c0,0.7,0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4
	c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8v1c0,0.6-0.2,1-0.5,1.3s-0.7,0.4-1.2,0.4s-0.8-0.1-1.1-0.3s-0.5-0.4-0.6-0.7h-1.7
	c0.1,0.8,0.4,1.4,1,1.8c0.6,0.5,1.4,0.7,2.3,0.7c0.7,0,1.3-0.1,1.8-0.4s0.9-0.7,1.1-1.2c0.3-0.5,0.4-1.1,0.4-1.7V374.2z
	M687.2,377.5c0-0.7-0.2-1.3-0.5-1.8s-0.7-0.9-1.2-1.2s-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.2
	c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.7,0.1,1.3,0.4,1.8s0.7,0.9,1.2,1.2c0.5,0.2,1.1,0.4,1.7,0.4c0.7,0,1.3-0.1,1.8-0.4
	c0.5-0.3,0.9-0.7,1.2-1.2S687.2,378.2,687.2,377.5z M692.2,374c-0.5,0-0.9,0.1-1.2,0.3c-0.4,0.2-0.7,0.5-0.8,0.9v-1h-1.7v6.6h1.7
	v-3.3c0-0.6,0.1-1.1,0.4-1.3c0.3-0.3,0.7-0.4,1.2-0.4h0.4V374z M699.8,374.2h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8
	c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1.1,1.2c-0.3,0.6-0.4,1.2-0.4,1.9s0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2
	c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.8v1h1.7V374.2z M705.5,313.2h-4.9v8.4h4.9v-1.4h-3.2V318h2.8v-1.3
	h-2.8v-2.1h3.2V313.2z M710.8,314.9c-0.5,0-0.9,0.1-1.2,0.3c-0.4,0.2-0.7,0.5-0.8,0.9v-1h-1.7v6.6h1.7v-3.3c0-0.6,0.1-1.1,0.4-1.3
	c0.3-0.3,0.7-0.4,1.2-0.4h0.4V314.9z M714.9,315.1h-1.2v-0.3c0-0.4,0-0.7,0.2-0.8c0.2-0.1,0.5-0.2,0.9-0.2v-1.4
	c-0.9-0.1-1.6,0.1-2.1,0.5c-0.5,0.4-0.7,1-0.7,1.8v0.3h-0.8v1.4h0.8v5.3h1.7v-5.3h1.2V315.1z M722.5,318.3c0-0.7-0.2-1.3-0.5-1.8
	s-0.7-0.9-1.2-1.2s-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.2c-0.4,0.5-0.5,1.1-0.5,1.8
	c0,0.7,0.1,1.3,0.4,1.8s0.7,0.9,1.2,1.2s1.1,0.5,1.7,0.4c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.7,1.2-1.2S722.5,319,722.5,318.3z
	M730,315h-1.5v3.7c0,0.5-0.1,0.9-0.4,1.2s-0.6,0.4-1.1,0.4s-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.7-0.4-1.2V315h-1.7v3.9
	c0,0.6,0.1,1.1,0.3,1.5c0.2,0.5,0.5,0.8,0.9,1s0.9,0.3,1.4,0.3c0.4,0,0.8,0,1.1-0.2s0.6-0.4,0.8-0.7v0.8h1.7V315z M738,312.8h-1.7
	v3.2c-0.2-0.4-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.5,0-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2s-0.4,1.1-0.5,1.9
	c0,0.7,0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.8v1h1.7V312.8z M753.4,316.8
	c0-2.7-2.2-4.9-4.9-4.9s-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9S753.4,319.5,753.4,316.8z M836.9,247.4c0-0.5-0.2-0.9-0.5-1.3
	c-0.3-0.4-0.6-0.6-1.1-0.7c0.4-0.1,0.7-0.4,1-0.7c0.3-0.3,0.4-0.7,0.4-1.2c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.4-0.5-0.6-0.9-0.8
	c-0.4-0.2-0.9-0.3-1.5-0.3h-3.5v8.4h3.6c0.6,0,1-0.1,1.5-0.3c0.5-0.2,0.8-0.5,1-0.8C836.8,248.2,836.9,247.8,836.9,247.4z
	M844.7,246.3c0-0.7-0.2-1.3-0.5-1.8s-0.7-0.9-1.2-1.2s-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.2
	c-0.4,0.5-0.5,1.1-0.5,1.8s0.1,1.3,0.4,1.8s0.7,0.9,1.2,1.2s1.1,0.5,1.7,0.4c0.7,0,1.3-0.1,1.8-0.4s0.9-0.7,1.2-1.2
	S844.7,247,844.7,246.3z M852.2,243h-1.5v3.7c0,0.5-0.1,0.9-0.4,1.2s-0.6,0.4-1.1,0.4s-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.7-0.4-1.2
	V243H846v3.9c0,0.6,0.1,1.1,0.3,1.5c0.2,0.5,0.5,0.8,0.9,1c0.4,0.2,0.9,0.3,1.4,0.3c0.4,0,0.8,0,1.1-0.2s0.6-0.4,0.8-0.7v0.8h1.7
	V243z M860.3,243.1h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1.1,1.2
	s-0.4,1.1-0.4,1.9c0,0.7,0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.8v1h1.7
	V243.1z M865.7,242.9c-0.5,0-0.9,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.8,0.9v-1H862v6.6h1.7v-3.3c0-0.6,0.1-1.1,0.4-1.3
	c0.3-0.3,0.7-0.4,1.2-0.4h0.4V242.9z M869.9,243.1h-1.2v-0.3c0-0.4,0-0.7,0.2-0.8c0.2-0.1,0.5-0.2,0.9-0.2v-1.4
	c-0.9-0.1-1.6,0.1-2.1,0.5c-0.5,0.4-0.7,1-0.7,1.8v0.3h-0.8v1.4h0.8v5.3h1.7v-5.3h1.2V243.1z M877.4,243.1h-1.7v0.9
	c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1.1,1.2c-0.2,0.5-0.4,1.1-0.4,1.9
	c0,0.7,0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.8v1h1.7V243.1z M891.4,244.8
	c0-2.7-2.2-4.9-4.9-4.9s-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9S891.4,247.5,891.4,244.8z M551.8,415.8c-0.3-0.2-0.6-0.2-0.9-0.2
	c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.8,0.2,1.1c0.2,0.3,0.4,0.5,0.6,0.7
	c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1c0.1-0.3,0-0.7-0.2-1
	S552,416,551.8,415.8z"
          />
          <path
            :class="{ heavy: ZoneNumber === 5, st1: ZoneNumber !== 5 }"
            @click="selectZone(5)"
            dataZone="5"
            class="st1"
            d="M697,51.4c0.1,0.5,0,1-0.4,1.5c-0.2,0.4-0.5,0.6-0.8,0.8c-0.3,0.2-0.6,0.2-0.9,0.2
	s-0.6-0.1-0.8-0.4c-0.4-0.3-0.6-0.6-0.7-1.1s0.1-1,0.4-1.5s0.7-0.8,1.2-0.9s0.9,0,1.3,0.3S696.9,50.9,697,51.4z M709.2,59.1
	c-0.3-0.2-0.5-0.3-0.8-0.3s-0.6,0-0.9,0.2c-0.3,0.2-0.6,0.4-0.8,0.7s-0.4,0.7-0.4,1s0,0.7,0.2,0.9c0.1,0.3,0.3,0.5,0.6,0.7
	s0.5,0.3,0.9,0.3c0.3,0,0.6,0,0.9-0.2c0.3-0.2,0.6-0.4,0.8-0.7s0.2-0.7,0.3-1c0-0.3,0-0.6-0.2-0.9S709.5,59.3,709.2,59.1z
	M686.9,44.4c-0.1-0.4-0.3-0.7-0.7-0.9c-0.3-0.2-0.7-0.3-1.1-0.3s-0.7,0.2-1,0.6l2.6,1.7C686.9,45.1,687,44.8,686.9,44.4z M759,53.7
	c-5,10.9-8.5,18.5-8.5,18.5s-30.6,21-65.8,5.3c-13.8-12-19.6-47-21.8-68.2c3-0.9,6-1.8,8.9-3c4.7-2,9-2.6,9.2,2.7
	c0.8,18.5,14.8,28,27.3,38.1c14.5,11.6,31.2,13.3,49,7C757.8,53.9,758.4,53.8,759,53.7z M679.6,28.6c0,1.9,1.6,3.5,3.5,3.5
	s3.5-1.6,3.5-3.5c0-1.9-1.6-3.5-3.5-3.5C681.2,25.1,679.6,26.7,679.6,28.6z M678,44.3l3.9-5.8l1.8,1.3l0.8-1.1l-5-3.5l-0.8,1.1
	l1.8,1.3l-3.9,5.8L678,44.3z M683.5,44.7l4,2.7c0.1-0.1,0.3-0.3,0.6-0.5c0.3-0.6,0.5-1.1,0.6-1.7c0.1-0.6,0-1.1-0.3-1.6
	s-0.7-0.9-1.2-1.3c-0.6-0.3-1.2-0.5-1.7-0.6c-0.5-0.1-1.1,0-1.6,0.3c-0.6,0.3-1,0.7-1.4,1.3c-0.4,0.5-0.6,1.1-0.7,1.7
	c-0.1,0.6,0,1.2,0.3,1.7s0.7,0.9,1.2,1.3c0.7,0.4,1.3,0.6,2,0.6s1.3-0.3,1.8-0.7l-1.5-1c-0.6,0.3-1.1,0.2-1.6-0.1
	c-0.4-0.2-0.6-0.5-0.7-0.9C683.2,45.5,683.3,45.1,683.5,44.7z M689.8,49.9l1.8-2.7l1.2,0.8l0.8-1.1l-1.2-0.9l0.9-1.4l-1.4-0.9
	l-0.9,1.4l-0.7-0.4l-0.8,1.1l0.7,0.4l-1.8,2.7c-0.8,1.1-0.6,2.1,0.6,2.9l0.9,0.6l0.8-1.2l-0.6-0.4c-0.2-0.1-0.4-0.3-0.4-0.4
	C689.6,50.2,689.7,50.1,689.8,49.9z M698,53.9c0.3-0.5,0.6-1.1,0.6-1.7c0.1-0.6,0-1.2-0.3-1.7s-0.7-0.9-1.2-1.3
	c-0.6-0.3-1.1-0.5-1.7-0.6c-0.6-0.1-1.2,0-1.7,0.3c-0.6,0.2-1,0.6-1.4,1.2c-0.3,0.5-0.6,1.1-0.6,1.7c-0.1,0.6,0,1.2,0.3,1.7
	s0.7,0.9,1.2,1.3c0.5,0.3,1.1,0.5,1.7,0.6c0.6,0.1,1.2,0,1.7-0.3C697.2,54.9,697.6,54.5,698,53.9z M702.4,60.8l3.7-5.5l-1.5-0.8
	l-2,3c-0.3,0.5-0.6,0.7-1,0.8c-0.3,0-0.7,0-1.1-0.3s-0.7-0.6-0.7-1s0.1-0.8,0.4-1.2l2.1-3l-1.4-0.9l-2.2,3.2c-0.4,0.5-0.5,1-0.6,1.5
	s0,0.9,0.2,1.3c0.2,0.4,0.6,0.7,1,1c0.4,0.2,0.7,0.3,1.1,0.4c0.4,0,0.8,0,1.1-0.1l-0.5,0.7L702.4,60.8z M709.1,65.3l3.7-5.5
	l-1.4-0.9l-0.5,0.8c0-0.3-0.1-0.7-0.3-1.1c-0.2-0.4-0.5-0.7-0.9-1c-0.5-0.3-1-0.5-1.5-0.5c-0.6,0-1.1,0.1-1.6,0.4s-1,0.7-1.4,1.2
	s-0.6,1.1-0.7,1.7c-0.1,0.6,0,1.1,0.2,1.6s0.5,0.9,1,1.2s0.9,0.5,1.3,0.5c0.5,0.1,0.8,0,1.2-0.1l-0.5,0.8L709.1,65.3z M718.5,64.5
	c-0.1-0.7-0.5-1.2-1.2-1.8c-0.4-0.2-0.7-0.3-1.1-0.4c-0.4,0-0.8,0-1.1,0.1l0.5-0.7l-1.4-0.9l-3.7,5.5l1.4,0.9l2.1-3
	c0.3-0.5,0.6-0.7,1-0.8c0.3,0,0.7,0,1.1,0.3s0.6,0.6,0.7,1c0,0.4-0.1,0.8-0.4,1.2l-2.1,3l1.4,0.9l2.2-3.2
	C718.4,65.9,718.6,65.2,718.5,64.5z M418.7,432.7c0.2,0.2,0.3,0.5,0.4,0.8c-0.1,0.3-0.1,0.7-0.2,0.9c-0.1,0.2-0.3,0.6-0.5,0.7
	c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0-0.6-0.1-1-0.2c-0.3-0.1-0.6-0.2-0.8-0.5c-0.2-0.2-0.3-0.5-0.4-0.8c0.1-0.3,0.1-0.6,0.2-0.9
	c0.1-0.2,0.2-0.5,0.5-0.7c0.2-0.1,0.5-0.2,0.8-0.3c0.3,0,0.6,0.1,1,0.2C418.3,432.3,418.6,432.4,418.7,432.7z M418.4,426.7
	c0.1,0.3,0.2,0.6,0.4,0.8c0.2,0.3,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2c0.3-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.4-0.5,0.5-0.7
	c0.1-0.3,0.1-0.6,0.2-0.9c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.3-0.5-0.4-0.9-0.6c-0.4-0.1-0.7-0.3-1-0.2s-0.6,0.2-0.8,0.3
	c-0.3,0.2-0.4,0.5-0.5,0.7C418.4,425.9,418.4,426.4,418.4,426.7z M415.1,439.9l-3.6-0.2l2.7,2.3L415.1,439.9z M421.3,419.6
	c0.1,0.3,0.2,0.6,0.4,0.8c0.2,0.3,0.5,0.4,0.8,0.5c0.4,0.1,0.7,0.2,1,0.2c0.3-0.1,0.6-0.2,0.8-0.3s0.4-0.5,0.5-0.7
	c0.1-0.3,0.1-0.6,0.2-0.9c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.3-0.5-0.4-0.9-0.6c-0.3-0.2-0.7-0.3-1-0.2c-0.3,0.1-0.6,0.2-0.8,0.3
	c-0.3,0.2-0.4,0.5-0.5,0.7C421.3,418.8,421.4,419.3,421.3,419.6z M448,390.7c0,10.3-1.9,33.1-5.1,42.7c-5,15.6-15.1,29.8-29,39.5
	l-26.7-0.9l-10.2-0.7c0.1-0.2,0.2-0.4,0.3-0.7c0,0,0-0.1,0.1-0.1c3.6-7.1,8.1-14,14.1-19.3c2.9-2.5,6.3-4.1,9.1-6.7
	c3-2.8,5.4-6.1,7.5-9.6c3.3-5.6,4.5-12.3,7.5-18c2.3-4.4,5-9,6.8-13.8c1.7-4.7,1.9-9,0.3-13.8c-1.9-5.4-4.5-9.1-7.2-14
	c-3.1-5.6-4.4-12.3-3.8-18.7c0-0.1,0-0.1,0-0.2c0.1-1.2,0.2-2.4,0.3-3.6l30,17.4C444.1,377.1,448.1,383.5,448,390.7z M420,412.7
	c0.1,0.2,0.2,0.4,0.5,0.5c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.5,0-0.7c-0.1-0.2-0.2-0.4-0.5-0.5
	c-0.2-0.1-0.5-0.1-0.7,0c-0.2,0.1-0.4,0.2-0.5,0.5C419.8,412.3,419.9,412.5,420,412.7z M418.6,438.5l-8.5-0.4l-0.7,1.8l6.3,5.7
	l0.7-1.6l-1.2-1.1l1.2-2.9l1.6,0.1L418.6,438.5z M423.5,434.3c-0.1-0.5-0.3-1-0.7-1.4c-0.3-0.5-0.8-0.8-1.3-1l-5.9-2.4L415,431
	l0.8,0.3c-0.4,0-0.7,0.2-1,0.5c-0.3,0.2-0.6,0.6-0.5,0.9c-0.2,0.5-0.3,1-0.2,1.5c0.1,0.5,0.4,1,0.7,1.4c0.3,0.4,0.8,0.7,1.4,1
	c0.6,0.3,1.2,0.4,1.7,0.3c0.6-0.1,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.7,0.9-1.2s0.2-0.9,0.2-1.3c-0.1-0.4-0.2-0.8-0.4-1.1l0.9,0.4
	c0.6,0.2,0.8,0.5,1,0.9c0.1,0.4,0.1,0.8-0.1,1.2c-0.2,0.5-0.4,0.7-0.7,0.9c-0.3,0.2-0.5,0.3-0.8,0.3l-0.6,1.5
	c0.7,0.2,1.3,0.2,1.9-0.2c0.7-0.4,1.2-1,1.5-1.8C423.4,435.4,423.6,434.8,423.5,434.3z M424.2,424.8l-5.8-2.4l-0.6,1.5l0.9,0.4
	c-0.3,0.1-0.7,0.2-1,0.5c-0.3,0.2-0.5,0.6-0.7,1c-0.2,0.5-0.3,1-0.2,1.5c0.1,0.5,0.4,1,0.7,1.4c0.3,0.4,0.8,0.7,1.4,1
	c0.6,0.3,1.2,0.4,1.7,0.3c0.6-0.1,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.7,0.9-1.2s0.2-0.9,0.2-1.3c-0.1-0.4-0.2-0.8-0.4-1.1l0.9,0.4
	L424.2,424.8z M427.3,417.7l-7.8-3.2l-0.6,1.5l2.8,1.2c-0.4,0-0.7,0.2-1,0.5c-0.3,0.2-0.6,0.5-0.7,0.9c-0.2,0.5-0.3,1-0.2,1.5
	c0.1,0.5,0.4,1,0.7,1.4c0.3,0.4,0.8,0.7,1.5,1.1c0.6,0.3,1.2,0.4,1.7,0.3c0.6-0.1,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.7,0.9-1.2
	s0.2-0.9,0.2-1.3c-0.1-0.4-0.2-0.8-0.4-1.1l0.9,0.4L427.3,417.7z M428.5,414.7l-5.8-2.4l-0.6,1.5l5.8,2.4L428.5,414.7z M429.9,411.8
	l-2.9-1.2c-0.5-0.2-0.9-0.5-1-0.8c-0.1-0.4-0.1-0.8,0.1-1.2l0.1-0.4l-1.6-0.7c-0.2,0.5-0.3,0.9-0.2,1.2c0,0.4,0.2,0.7,0.4,1
	l-0.9-0.4l-0.6,1.5l5.9,2.4L429.9,411.8z M430.5,389.5c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3
	S430.5,391.3,430.5,389.5z"
          />
          <path
            :class="{ heavy: ZoneNumber === 3, st2: ZoneNumber !== 3 }"
            @click="selectZone(3)"
            dataZone="3"
            class="st2"
            d="M229.7,581.5c-0.1-0.2-0.2-0.6-0.2-1s0-0.7,0.2-1c0.1-0.3,0.3-0.6,0.6-0.7s0.6-0.2,0.9-0.2s0.6,0,0.9,0.2
	c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.3,0.3,0.6,0.2,0.9c0,0.5-0.1,0.8-0.2,1.1c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.1-0.6,0.2-0.9,0.2
	s-0.6,0-0.9-0.2C230.1,582,229.9,581.8,229.7,581.5z M119.8,786.9c-0.9-0.5-1.8-0.7-2.9-0.7c-1.1,0-2.1,0.2-2.9,0.7s-1.5,1.2-2,2.1
	c-0.5,0.9-0.7,2-0.7,3.2s0.2,2.3,0.7,3.2s1.1,1.6,2,2.1c0.8,0.5,1.8,0.7,2.9,0.7c1.1,0.1,2-0.2,2.9-0.7c0.8-0.5,1.5-1.2,2-2.1
	c0.5-0.9,0.7-2,0.7-3.2s-0.2-2.3-0.7-3.2S120.7,787.4,119.8,786.9z M161.5,763.5h5.2l-2.6-7.6L161.5,763.5z M254.6,582.5
	c0.4,0,0.7-0.1,0.9-0.2c0.3-0.1,0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.7,0.2-1.1c0-0.6-0.2-1.1-0.5-1.5c-0.3-0.3-0.7-0.5-1.2-0.5
	s-0.9,0.2-1.2,0.5s-0.5,0.8-0.5,1.5c0,0.6,0.2,1.1,0.5,1.5C253.7,582.3,254.1,582.5,254.6,582.5z M182.7,786.2h-2.6v12.1h2.6
	c1.9-0.1,3.3-0.6,4.4-1.6c1-1,1.6-2.5,1.6-4.4s-0.5-3.4-1.6-4.5C186,786.7,184.6,786.2,182.7,786.2z M238.5,582.2
	c0.3,0.2,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1c0-0.3-0.1-0.6-0.2-0.9
	c-0.2-0.3-0.4-0.5-0.6-0.7c-0.3-0.2-0.6-0.2-0.9-0.2s-0.6,0.1-0.9,0.2s-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.8,0.2,1
	C238.1,581.8,238.3,582,238.5,582.2z M662.4,241.9c-0.3,0-0.6,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9h2.6c0-0.4-0.1-0.6-0.4-0.9
	S662.7,241.9,662.4,241.9z M899.7,115.4c0.3-0.1,0.5-0.2,0.7-0.3C900.1,115.2,899.9,115.3,899.7,115.4z M279.2,578.9
	c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1,0.4s-0.5,0.6-0.5,1h3.1C279.7,579.5,279.5,579.1,279.2,578.9z M199,763.5h5.2l-2.6-7.6
	L199,763.5z M221.7,755.5c-0.5-0.5-1.2-0.7-2.2-0.7h-3.1v5.3h3.1c1,0,1.7-0.2,2.2-0.7s0.7-1.1,0.7-2
	C222.4,756.6,222.2,755.9,221.7,755.5z M635.8,241.9c-0.3,0-0.6,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9h2.6c0-0.4-0.1-0.6-0.4-0.9
	S636.1,241.9,635.8,241.9z M629.9,243.3h-1.4v1.9h1.4c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7
	C630.5,243.4,630.2,243.3,629.9,243.3z M630.6,242.1c0.2-0.1,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.2-0.8-0.2h-1.3v1.6
	h1.3C630.1,242.3,630.4,242.2,630.6,242.1z M248.4,794.9h5.2l-2.6-7.6L248.4,794.9z M303.3,605.5c-0.3-0.2-0.6-0.2-0.9-0.2
	s-0.6,0.1-0.9,0.2s-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.8,0.2,1c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2
	s0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1c0.1-0.2,0-0.6-0.2-0.9S303.5,605.7,303.3,605.5z M741.6,215.7
	c-0.4,0-0.7,0.1-1,0.3s-0.4,0.5-0.5,1h2.9c0-0.4-0.2-0.7-0.4-0.9C742.4,215.9,742,215.7,741.6,215.7z M562.2,290.4
	c-0.3-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.6c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.4,0.1,0.7,0.2,0.9
	c0.1,0.3,0.3,0.5,0.6,0.6c0.2,0.1,0.5,0.2,0.8,0.2s0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.6c0.1-0.3,0.2-0.6,0.2-0.9
	s-0.1-0.7-0.2-0.9C562.7,290.7,562.5,290.5,562.2,290.4z M899.7,115.4l1.6,42.5L773,213.1h-0.2c-86.5,41.1-187.6,94.5-235.9,139.6
	c-59.3,123.1-86.7,206.4-98.7,260.6v54.4c-49-0.8-101.5-2-146.6-2c0,4.2,0.5,7.9,0.5,11.4c0.1,46.7-1.4,103.3-0.9,149.9
	c0.1,8.4-1.7,12.6-11.3,12c-5.3-0.3-11.1-0.5-16.1,1.5c-27.5,11.3-33.6,13-40.6,39.5c-6.3,24-5.4,56.5-3,81.2
	c0.3,3,0.5,6.2,0.9,10.2H-15c-2.9,9-5.5,14.8-8.1,21.8c-0.3-0.1-0.6-0.3-0.9-0.4c2.1-15.8,3.5-31.6,6.6-47.2
	c1.8-8.8,5.6-17.4,9.6-25.6c2.1-4.4,4.6-9.9,12.5-8.4c2.1,0.4,6.5-4.4,8-7.7c3.9-8.4,6.5-17.4,10-26c0.9-2.3,3-4.1,4.5-6.2
	c1.6-2.2,4.8-5.5,4.2-6.4c-4.2-6.3,0.9-10.3,3.3-14.6c5.7-10.1,12.1-19.8,18.2-29.7c1-1.6,2.9-3.2,2.8-4.7c-0.5-11,8.7-16.3,15-22.8
	c8.7-9.1,19.1-16.5,28.2-25.3c8.8-8.5,13.3-19.3,12.1-31.3c-1.1-10.8,1.4-20,6.2-29.9c6.2-12.7,9.4-26.7,14.4-40
	c1.2-3.2,3.7-6.6,6.6-8.4c9.7-6.3,19.4-13,30-17.5c17.5-7.5,23.3-21.7,28.1-37.8c3.6-12.3,7.3-25.3,14.6-35.5
	c7.8-10.8,15.9-22.5,34.1-22.3c12.9,0.2,25-3.3,36.8-8c4.6,0.7,9.3,1,13.9,1.3c27.5,1.7,56.7-9.7,81.4-22
	c37.5-18.6,55.7-36.7,71-69.5c7.2-15.5,9.9-27.7,16.5-43.5c14.9-35.6,24.2-42.3,40.6-73.5c0,0,4.6-8.7,9-17.8
	c9.2-19,23.3-35.4,41-47c12.7-8.3,28.8-16.4,48.2-21.6c5.5-1.5,11-3.2,16.4-5.4c47.8-19.5,99.1-46.2,131.1-69.6
	c3.4-2.5,7.2-4.3,11.3-5.5C798.4,153.2,876.7,124,899.7,115.4z M251.3,580.5c0,0.7,0.1,1.3,0.4,1.8c0.3,0.5,0.7,0.9,1.2,1.2
	c0.5,0.3,1.1,0.5,1.7,0.4c0.7,0,1.3-0.1,1.8-0.4s0.9-0.7,1.2-1.2s0.5-1.1,0.5-1.8s-0.2-1.3-0.5-1.8s-0.7-0.9-1.2-1.2
	c-0.5-0.3-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.2C251.4,579.2,251.3,579.8,251.3,580.5z M243.6,577.2l2.7,6.5
	l-1.4,3.3h1.8l4.1-9.8h-1.9l-1.7,4.6l-1.7-4.6H243.6z M235.9,580.6c0,0.7,0.1,1.3,0.4,1.8c0.3,0.5,0.6,0.9,1.1,1.2
	c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8v1h1.7v-6.6h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8
	s-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2C236,579.2,235.9,579.8,235.9,580.6z M227.7,580.6c0,0.7,0.1,1.3,0.4,1.8
	c0.3,0.5,0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8v1h1.7v-6.6h-1.7v0.9
	c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2C227.9,579.2,227.7,579.8,227.7,580.6z
	M126.2,792.2c0-1.8-0.4-3.4-1.2-4.8c-0.9-1.4-2-2.5-3.4-3.3c-1.4-0.8-3-1.2-4.7-1.2s-3.3,0.4-4.7,1.2s-2.6,1.9-3.4,3.3
	c-0.9,1.4-1.3,3-1.3,4.8s0.5,3.4,1.3,4.8c0.8,1.4,2,2.5,3.4,3.3s3,1.2,4.7,1.2s3.3-0.4,4.7-1.2c1.4-0.8,2.6-1.9,3.4-3.3
	S126.2,794,126.2,792.2z M131.9,792.1c0-1.2,0.2-2.3,0.7-3.2c0.4-0.9,1.1-1.6,1.9-2.1s1.7-0.7,2.8-0.7c1,0,1.8,0.3,2.6,0.7
	c0.8,0.5,1.4,1.1,1.8,2h4.2c-0.6-1.8-1.7-3.3-3.2-4.3c-1.6-1.1-3.4-1.6-5.4-1.6c-1.7,0-3.3,0.4-4.7,1.2s-2.5,1.9-3.3,3.3
	c-0.9,1.4-1.3,3-1.2,4.7c0,1.8,0.4,3.4,1.2,4.8c0.8,1.4,1.9,2.5,3.3,3.3c1.4,0.8,3,1.2,4.7,1.2c2.1,0,3.9-0.6,5.4-1.6
	c1.5-1.1,2.6-2.5,3.2-4.4h-4.2c-0.5,0.9-1,1.6-1.8,2c-0.7,0.5-1.6,0.7-2.6,0.7s-2-0.2-2.8-0.7s-1.4-1.2-1.9-2.1
	C132.1,794.4,131.9,793.3,131.9,792.1z M146.7,770.2c1.4,0,2.5-0.2,3.5-0.7s1.7-1.2,2.2-2s0.8-1.7,0.8-2.7c0-1.1-0.3-2-0.8-2.7
	c-0.5-0.7-1.2-1.3-1.9-1.6s-1.7-0.7-2.9-1c-0.8-0.3-1.5-0.5-2-0.7s-0.9-0.5-1.2-0.8s-0.5-0.7-0.5-1.2c0-0.7,0.2-1.2,0.6-1.6
	c0.4-0.3,1-0.5,1.7-0.5c0.8,0,1.5,0.2,2,0.6c0.5,0.5,0.8,1,0.8,1.6h4c-0.1-1.6-0.7-2.9-1.9-3.8s-2.7-1.4-4.6-1.4
	c-1.2,0-2.3,0.2-3.3,0.6c-1,0.4-1.8,1-2.3,1.8s-0.8,1.7-0.8,2.8c0,1.2,0.3,2.1,0.8,2.8c0.5,0.6,1.1,1.2,1.9,1.5s1.7,0.7,2.9,1
	c0.9,0.3,1.6,0.5,2.1,0.7s0.9,0.5,1.2,0.8s0.5,0.8,0.5,1.3c0,0.7-0.2,1.2-0.7,1.6c-0.5,0.4-1.1,0.6-2,0.6c-0.9,0-1.5-0.3-2-0.7
	c-0.5-0.5-0.7-1-0.8-1.8h-3.9c0,1.1,0.3,2.1,0.9,2.9c0.6,0.8,1.4,1.5,2.4,1.9S145.6,770.1,146.7,770.2z M152,792.1
	c0-1.2,0.2-2.3,0.7-3.2c0.4-0.9,1.1-1.6,1.9-2.1c0.8-0.5,1.7-0.7,2.8-0.7c1,0,1.8,0.3,2.6,0.7c0.8,0.5,1.4,1.1,1.8,2h4.2
	c-0.6-1.8-1.7-3.3-3.2-4.3c-1.6-1.1-3.4-1.6-5.4-1.6c-1.7,0-3.3,0.4-4.7,1.2s-2.5,1.9-3.3,3.3c-0.9,1.4-1.3,3-1.2,4.7
	c0,1.8,0.4,3.4,1.2,4.8c0.8,1.4,1.9,2.5,3.3,3.3c1.4,0.8,3,1.2,4.7,1.2c2.1,0,3.9-0.6,5.4-1.6c1.5-1.1,2.6-2.5,3.2-4.4h-4.2
	c-0.5,0.9-1,1.6-1.8,2c-0.7,0.5-1.6,0.7-2.6,0.7s-2-0.2-2.8-0.7s-1.4-1.2-1.9-2.1C152.2,794.4,152,793.3,152,792.1z M172.8,783.1
	h-3.7v18.3h3.7V783.1z M172.9,770l-6.6-18.3H162l-6.6,18.3h3.8l1.2-3.5h7.3l1.3,3.5H172.9z M175.2,769.9h3.7v-7.8h7.8v7.8h3.7v-18.2
	l-3.7-0.1v7.5h-7.8v-7.5h-3.7V769.9z M192.4,792.2c0-1.8-0.4-3.4-1.2-4.8s-1.9-2.4-3.4-3.2c-1.4-0.7-3.1-1.1-5.1-1.2h-6.4v18.3h6.4
	c2,0,3.6-0.3,5.1-1.1s2.6-1.8,3.4-3.2S192.4,794,192.4,792.2z M205.7,783h-10.6v18.3h10.6v-3h-6.9v-4.8h6.1v-2.9h-6.1V786h6.9V783z
	M206.5,770h3.9l-6.6-18.3h-4.3l-6.6,18.3h3.8l1.2-3.5h7.3L206.5,770z M224.7,783H221v12.6l-8.3-12.6H209v18.3h3.7v-12.5l8.3,12.5
	h3.7V783z M226.4,769.8l-4.4-7.4c1.4-0.4,2.4-1,3.1-2c0.7-1,1-2.1,1-3.3c0-1-0.2-2-0.7-2.8s-1.2-1.5-2.2-2s-2.2-0.7-3.6-0.7h-6.9
	v18.3h3.7v-7.1h1.7l4,7.1L226.4,769.8z M226.4,592c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5s2.2,5,5,5C224.2,597,226.4,594.8,226.4,592z
	M227.1,582.5h-2.7v-7.1h-1.7v8.4h4.4V582.5z M240.7,783.1h-13.5v3h4.9v15.3h3.7v-15.3h4.9V783.1z M242,770h3.9l-6.6-18.3H235
	l-6.6,18.3h3.8l1.2-3.5h7.3L242,770z M259.8,801.4l-6.6-18.3h-4.3l-6.6,18.3h3.8l1.2-3.5h7.3l1.3,3.5H259.8z M262,583.9
	c0.4,0,0.8,0,1.1-0.2s0.6-0.4,0.8-0.7v0.8h1.7v-6.6h-1.5v3.7c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1.1,0.4s-0.8-0.1-1.1-0.4
	s-0.4-0.7-0.4-1.2v-3.7h-1.7v3.9c0,0.6,0.1,1.1,0.3,1.5c0.2,0.5,0.5,0.8,0.9,1C261,583.8,261.5,583.9,262,583.9z M271.8,798.4h-6
	V783h-3.7v18.3h9.7V798.4z M273.6,580c0-0.9-0.2-1.6-0.7-2.1s-1.1-0.8-2-0.7c-0.4,0-0.8,0-1.1,0.2c-0.4,0.2-0.7,0.4-0.9,0.7v-0.8
	h-1.7v6.6h1.7v-3.7c0-0.5,0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.4,1.1-0.4s0.8,0.1,1.1,0.4s0.4,0.7,0.4,1.2v3.7h1.7V580z M281.4,580.4
	c0-0.6-0.1-1.2-0.4-1.7s-0.7-0.9-1.2-1.2c-0.5-0.3-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.2s-0.4,1.1-0.4,1.8
	s0.1,1.3,0.4,1.8c0.3,0.5,0.7,0.9,1.2,1.2c0.5,0.3,1.1,0.4,1.7,0.4c0.8,0,1.5-0.2,2-0.6c0.5-0.4,0.9-1,1.1-1.6h-1.8
	c-0.2,0.5-0.7,0.8-1.3,0.8c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.5-0.6-0.5-1.1h4.9C281.4,580.8,281.4,580.6,281.4,580.4z M297.9,608.3
	c0-0.6-0.1-1-0.4-1.3s-0.6-0.5-0.9-0.7s-0.8-0.3-1.3-0.5c-0.4-0.1-0.7-0.2-0.9-0.3c-0.3-0.1-0.5-0.3-0.6-0.4
	c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3c0.4,0,0.7,0.1,0.9,0.3c0.3,0.2,0.4,0.4,0.4,0.7h1.8
	c-0.1-0.8-0.4-1.4-0.9-1.8c-0.5-0.4-1.2-0.6-2.1-0.6c-0.5,0-1.1,0.1-1.5,0.3c-0.4,0.1-0.7,0.4-1,0.8s-0.4,0.8-0.4,1.3
	c0,0.6,0.2,1,0.4,1.3c0.3,0.3,0.6,0.5,0.9,0.7s0.8,0.3,1.3,0.5c0.4,0.1,0.7,0.2,0.9,0.3c0.3,0.1,0.5,0.3,0.6,0.4
	c0.1,0.1,0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.9,0.3c-0.4,0-0.7-0.1-0.9-0.3c-0.3-0.2-0.4-0.5-0.4-0.8h-1.8
	c0,0.5,0.1,0.9,0.4,1.3c0.3,0.4,0.6,0.7,1.1,0.9c0.4,0.2,1,0.3,1.6,0.3c0.6,0,1.2-0.1,1.6-0.3c0.4-0.2,0.8-0.6,1-0.9
	C297.8,609.2,297.9,608.7,297.9,608.3z M305.8,604.1h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4
	s-0.8,0.7-1.1,1.2c-0.2,0.5-0.4,1.1-0.4,1.9c0,0.7,0.1,1.3,0.4,1.8c0.3,0.5,0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4
	c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8v1h1.7V604.1z M309.1,610.6v-3.7c0-0.5,0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.4,1.1-0.4
	s0.8,0.1,1.1,0.4s0.4,0.7,0.4,1.2v3.7h1.7v-3.7c0-0.5,0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.4,1.1-0.4s0.8,0.1,1.1,0.4s0.4,0.7,0.4,1.2v3.7
	h1.7v-3.9c0-0.9-0.3-1.6-0.8-2.1s-1.1-0.7-2.2-0.7c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2-0.4-0.5-0.7-0.9-0.9
	c-0.4-0.2-0.9-0.3-1.4-0.3c-0.4,0-0.8,0-1.1,0.2s-0.6,0.4-0.8,0.7V604h-1.7v6.6H309.1z M319.4,622c0-2.8-2.2-5-5-5s-5,2.2-5,5
	s2.2,5,5,5S319.4,624.8,319.4,622z M326.6,604.1h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4
	s-0.8,0.7-1.1,1.2s-0.4,1.1-0.4,1.9c0,0.7,0.1,1.3,0.4,1.8s0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3
	c0.4-0.2,0.7-0.5,0.9-0.8v1h1.7V604.1z M331.9,603.9c-0.5,0-0.9,0.1-1.2,0.3s-0.6,0.4-0.8,0.9v-1h-1.7v6.6h1.7v-3.3
	c0-0.6,0.1-1.1,0.4-1.3c0.3-0.3,0.7-0.4,1.2-0.4h0.4V603.9z M339.5,604.1h-1.7v0.9c-0.2-0.3-0.5-0.6-0.9-0.8
	c-0.4-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.5,0.4s-0.8,0.7-1.1,1.2c-0.2,0.5-0.4,1.1-0.4,1.9c0,0.7,0.1,1.3,0.4,1.8
	c0.3,0.5,0.6,0.9,1.1,1.2c0.4,0.3,0.9,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8v1h1.7V604.1z M532.2,300.1
	c0-2.5-2.1-4.6-4.6-4.6s-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6S532.2,302.6,532.2,300.1z M541.3,287.4h-1.6l-2.4,5.6l-2.4-5.6h-1.7v7.5
	h1.5V290l2,4.9h1.1l2-4.9v4.9h1.5h0V287.4z M548.7,288.9h-1.5v0.9c-0.2-0.3-0.5-0.5-0.8-0.7c-0.4-0.2-0.8-0.3-1.2-0.3
	c-0.5,0-1,0.1-1.4,0.4c-0.4,0.3-0.8,0.6-1,1.1c-0.3,0.4-0.4,1-0.4,1.5c0,0.6,0.2,1.1,0.4,1.6c0.3,0.5,0.6,0.8,1,1.1s0.9,0.4,1.4,0.4
	s0.9-0.1,1.2-0.3s0.6-0.4,0.8-0.7v0.9h1.5V288.9z M553.4,290.4v-1.6c-0.4,0-0.8,0.1-1.1,0.3s-0.5,0.4-0.8,0.7v-0.9H550v6h1.5v-3
	c0-0.6,0.2-1,0.4-1.2c0.3-0.2,0.6-0.3,1.1-0.3H553.4z M557.8,290.4v-1.6c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.2-0.6,0.4-0.8,0.7v-0.9h-1.5
	v6h1.5v-3c0-0.6,0.2-1,0.4-1.2c0.3-0.2,0.6-0.3,1.1-0.3H557.8z M564.6,288.9h-1.5v0.9c-0.2-0.3-0.5-0.5-0.8-0.7
	c-0.4-0.2-0.8-0.3-1.2-0.3c-0.5,0-1,0.1-1.4,0.4c-0.4,0.3-0.8,0.6-1,1.1c-0.2,0.4-0.4,1-0.4,1.5c0,0.6,0.2,1.1,0.4,1.6
	c0.3,0.5,0.6,0.8,1,1.1s0.9,0.4,1.4,0.4s0.9-0.1,1.2-0.3s0.6-0.4,0.8-0.7v0.9h1.5V288.9z M568.9,291.9l2.6-3h-2l-2,2.5v-4.5H566v8
	h1.5v-2.5l2,2.5h2L568.9,291.9z M577.9,291.8c0-0.5-0.2-1.1-0.4-1.5c-0.2-0.4-0.6-0.8-1-1c-0.4-0.3-0.9-0.4-1.5-0.4
	s-1.1,0.1-1.6,0.4c-0.5,0.3-0.8,0.6-1.1,1.1c-0.3,0.4-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.4,1.6s0.7,0.8,1.1,1.1s0.9,0.4,1.5,0.4
	c0.7,0,1.3-0.2,1.8-0.6c0.5-0.4,0.8-0.8,1-1.4h-1.6c-0.2,0.5-0.6,0.7-1.2,0.7c-0.4,0-0.7-0.2-1-0.4s-0.5-0.6-0.5-1h4.3
	C577.8,292.2,577.8,292,577.9,291.8z M583.4,293.2c0-0.3-0.1-0.7-0.3-0.9s-0.4-0.4-0.7-0.5s-0.7-0.2-1.1-0.3s-0.7-0.2-0.9-0.3
	c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.6-0.2s0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.6h1.4
	c0-0.6-0.3-1.1-0.7-1.4s-1-0.5-1.7-0.5c-0.4,0-0.8,0-1.2,0.2c-0.3,0.1-0.6,0.3-0.8,0.6s-0.3,0.6-0.3,0.9c0,0.4,0.1,0.8,0.3,1
	s0.4,0.4,0.7,0.5s0.7,0.2,1.1,0.3s0.7,0.2,0.9,0.3c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1,0,0.3-0.2,0.4c-0.2,0.1-0.4,0.2-0.7,0.2
	s-0.5,0-0.7-0.2s-0.3-0.4-0.3-0.6h-1.5c0.1,0.4,0.2,0.7,0.4,1c0.2,0.3,0.5,0.5,0.9,0.7c0.4,0.2,0.9,0.3,1.3,0.2c0.4,0,0.8,0,1.2-0.2
	c0.3-0.1,0.6-0.3,0.8-0.6C583.3,293.9,583.4,293.5,583.4,293.2z M590.4,294.9v-3.5c0-0.6-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.8-0.9
	c-0.4-0.2-0.8-0.3-1.3-0.3c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.6v-2.7h-1.5v8h1.5v-3.3c0-0.5,0.2-0.8,0.4-1.1
	c0.3-0.3,0.6-0.4,1-0.4s0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1.1v3.3H590.4z M624.6,252.4c0-2.8-2.2-5-5-5s-5,2.2-5,5c0,2.8,2.2,5,5,5
	C622.4,257.4,624.6,255.2,624.6,252.4z M632.4,244.5c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1-0.6c0.4-0.1,0.7-0.3,0.9-0.6
	c0.2-0.3,0.3-0.6,0.3-1c0-0.3-0.1-0.7-0.3-0.9s-0.5-0.4-0.8-0.6c-0.3-0.1-0.7-0.2-1.2-0.2h-2.8v6.9h3c0.5,0,0.9,0,1.2-0.2
	c0.4-0.2,0.6-0.4,0.8-0.7S632.4,244.9,632.4,244.5z M638.5,243.3c0-0.5-0.1-1-0.3-1.4c-0.3-0.5-0.6-0.8-1-1
	c-0.4-0.2-0.9-0.3-1.4-0.3s-1,0.1-1.4,0.3c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.3,0.9-0.3,1.5c0,0.6,0.2,1.1,0.4,1.5
	c0.3,0.4,0.6,0.8,1,1c0.4,0.3,0.9,0.4,1.4,0.4c0.6,0,1.2-0.2,1.6-0.5s0.7-0.8,0.9-1.3h-1.5c-0.2,0.5-0.6,0.7-1.1,0.7
	c-0.4,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.4-0.9h4C638.5,243.8,638.6,243.7,638.5,243.3z M644.8,246.3v-3.2c0-0.7-0.2-1.3-0.6-1.7
	c-0.4-0.4-1-0.6-1.6-0.6c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.7,0.6v-0.7h-1.4v5.4h1.4v-3c0-0.4,0.1-0.8,0.3-1
	c0.2-0.3,0.5-0.4,0.9-0.4s0.7,0.2,0.9,0.4s0.3,0.6,0.3,1v3H644.8z M647.5,240.9h-1.4v5.5h1.4V240.9z M647.6,239.4
	c0-0.3,0-0.4-0.2-0.6c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0-0.6,0.2c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.3,0,0.4,0.2,0.6
	c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0,0.5,0,0.6-0.2S647.6,239.7,647.6,239.4z M658.7,246.3v-6.9h-1.5l-2.2,5.1l-2.2-5.1h-1.6v6.9h1.4
	v-4.5l1.9,4.5h1l1.8-4.5v4.5H658.7z M665.1,243.3c0-0.5-0.1-1-0.3-1.4c-0.3-0.5-0.6-0.8-1-1c-0.4-0.2-0.9-0.3-1.4-0.3
	s-1,0.1-1.4,0.3c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.3,0.9-0.3,1.5c0,0.6,0.2,1.1,0.4,1.5c0.3,0.4,0.6,0.8,1,1c0.4,0.3,0.9,0.4,1.4,0.4
	c0.6,0,1.2-0.2,1.6-0.5s0.7-0.8,0.9-1.3h-1.5c-0.2,0.5-0.6,0.7-1.1,0.7c-0.4,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.4-0.9h4
	C665.1,243.8,665.1,243.7,665.1,243.3z M667.5,239h-1.4v7.3h1.4V239z M670.3,239h-1.4v7.3h1.4V239z M677,240.9h-1.4v0.7
	c-0.1-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.7-0.2-1.1-0.2c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.4-0.3,0.9-0.3,1.5
	c0,0.6,0.1,1.1,0.3,1.5c0.2,0.4,0.5,0.8,0.9,1c0.4,0.3,0.8,0.4,1.3,0.4c0.4,0,0.8-0.1,1.1-0.3c0.3-0.1,0.5-0.3,0.7-0.6v0.8h1.4
	V240.9z M679.7,239h-1.4v7.3h1.4V239z M722.8,229.4c0-2.5-2.1-4.6-4.6-4.6s-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6
	S722.8,231.9,722.8,229.4z M723.9,212.9h-1.8l-2.4,5.7l-2.4-5.7h-1.7v7.6h1.5v-5l2.1,5h1.2l2-5v5h1.5h0V212.9z M727,214.5h-1.5v6.1
	h1.5V214.5z M727.2,212.9c0-0.2-0.1-0.4-0.3-0.6s-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6
	s0.4,0.3,0.7,0.3s0.5-0.2,0.7-0.3C727.1,213.4,727.2,213.2,727.2,212.9z M734.5,212.5h-1.6v2.9c-0.3-0.3-0.6-0.5-0.9-0.7
	s-0.7-0.3-1.1-0.3c-0.5,0-1,0.1-1.4,0.4s-0.8,0.6-1,1.1c-0.3,0.5-0.4,1-0.4,1.7c0,0.6,0.2,1.1,0.4,1.6s0.6,0.8,1,1.1
	s0.9,0.4,1.4,0.4s0.9-0.1,1.2-0.3c0.3-0.2,0.6-0.4,0.8-0.7v0.9h1.6V212.5z M737.4,212.5h-1.5v8.1h1.5V212.5z M744.7,217.5
	c0-0.6-0.1-1.1-0.4-1.6s-0.6-0.9-1.1-1.1c-0.4-0.3-0.9-0.4-1.5-0.4s-1.1,0.1-1.6,0.4s-0.8,0.6-1.1,1.1c-0.3,0.5-0.4,1.1-0.4,1.7
	c0,0.6,0.1,1.1,0.4,1.6s0.6,0.8,1.1,1.1s1,0.4,1.6,0.4c0.7,0,1.3-0.2,1.8-0.6c0.5-0.3,0.8-0.8,1-1.4h-1.7c-0.2,0.5-0.6,0.7-1.2,0.7
	c-0.4,0-0.7-0.2-1-0.4s-0.5-0.6-0.5-1h4.4C744.6,217.8,744.6,217.6,744.7,217.5z M748.8,214.4h-1.4v-1.5h-1.5v1.5h-0.7v1.3h0.7v2.9
	c0,1.3,0.6,1.9,1.9,1.9h1v-1.3h-0.7c-0.2,0-0.4-0.1-0.5-0.1s-0.1-0.2-0.1-0.4v-2.9l1.3-0.1V214.4z M732,216
	c-0.3-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2s-0.4,0.3-0.6,0.6c-0.2,0.3-0.2,0.6-0.2,0.9s0.1,0.7,0.2,1c0.1,0.3,0.3,0.5,0.6,0.6
	c0.2,0.1,0.5,0.2,0.8,0.2s0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.6c0.1-0.3,0.2-0.6,0.2-1c0.1-0.3,0-0.6-0.2-0.9
	C732.5,216.3,732.3,216.1,732,216z M324,605.5c-0.3-0.2-0.6-0.2-0.9-0.2s-0.6,0.1-0.9,0.2s-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1
	s0.1,0.8,0.2,1c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.6,0.2-1.1
	c0.1-0.2,0-0.6-0.2-0.9S324.2,605.7,324,605.5z M337,605.5c-0.3-0.2-0.6-0.2-0.9-0.2s-0.6,0.1-0.9,0.2s-0.5,0.4-0.6,0.7
	c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.8,0.2,1c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.6-0.7
	c0.1-0.3,0.2-0.6,0.2-1.1c0.1-0.2,0-0.6-0.2-0.9S337.2,605.7,337,605.5z M234.5,763.5h5.2l-2.6-7.6L234.5,763.5z M546.3,290.4
	c-0.3-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.6c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.4,0.1,0.7,0.2,0.9
	c0.1,0.3,0.3,0.5,0.6,0.6c0.2,0.1,0.5,0.2,0.8,0.2s0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.6c0.1-0.3,0.2-0.6,0.2-0.9
	s-0.1-0.7-0.2-0.9C546.8,290.7,546.6,290.5,546.3,290.4z M674.9,242.1c-0.2-0.2-0.5-0.2-0.7-0.2s-0.5,0.1-0.7,0.2
	c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.9c0,0.4,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.1,0.5,0.2,0.7,0.2
	c0.3,0,0.5-0.1,0.7-0.2s0.4-0.3,0.5-0.6c0.1-0.2,0.2-0.5,0.2-0.9c0-0.2-0.1-0.5-0.2-0.8C675.3,242.5,675.1,242.3,674.9,242.1z
	M575.8,290.4c-0.3-0.2-0.6-0.3-1-0.3s-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.5,0.9h2.8C576.2,290.9,576.1,290.6,575.8,290.4z
	M900.4,115.1c0.3-0.1,0.6-0.2,0.8-0.3C901.1,114.8,900.8,114.9,900.4,115.1z"
          />
          <path
            :class="{ heavy: ZoneNumber === 2, st3: ZoneNumber !== 2 }"
            @click="selectZone(2)"
            dataZone="2"
            class="st3"
            d="M463.2,362.3c-0.2,0.1-0.4,0.2-0.6,0.2s-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.8
	s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.3,0.5,0.5
	c0.1,0.2,0.1,0.5,0.2,0.8c0,0.3-0.1,0.6-0.2,0.8C463.6,362,463.4,362.2,463.2,362.3z M389.5,483c-0.3,0-0.6,0.1-0.9,0.3
	c-0.3,0.2-0.3,0.4-0.4,0.9h2.5c0-0.3-0.1-0.6-0.3-0.9C390.2,483.1,389.8,483,389.5,483z M694.2,146.1c-0.3-0.3-0.6-0.4-1-0.4
	c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.5-0.5,1h2.9C694.6,146.7,694.5,146.3,694.2,146.1z M749,136.8c-0.2-0.1-0.4-0.2-0.7-0.2
	s-0.4,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.6,0.6c-0.1,0.2-0.2,0.6-0.2,0.9s0.1,0.7,0.2,0.9c0.1,0.2,0.3,0.4,0.6,0.6s0.4,0.2,0.7,0.2
	c0.2,0,0.4-0.1,0.7-0.2s0.4-0.3,0.6-0.6c0.1-0.2,0.2-0.6,0.2-0.9s-0.1-0.6-0.2-0.9C749.4,137.2,749.2,137,749,136.8z M545.2,197.7
	c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8
	c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.8
	c-0.1-0.3-0.1-0.6-0.2-0.8C545.6,198,545.4,197.8,545.2,197.7z M524.8,197.7c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2
	c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2
	c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.8c-0.1-0.3-0.1-0.6-0.2-0.8C525.2,198,525,197.8,524.8,197.7z
	M677,199.5c-0.2-0.1-0.5-0.2-0.7-0.2s-0.5,0.1-0.7,0.2s-0.5,0.4-0.6,0.6s-0.2,0.6-0.2,0.9c0,0.4,0.1,0.7,0.2,0.9
	c0.1,0.2,0.4,0.5,0.6,0.6c0.2,0.1,0.5,0.2,0.7,0.2s0.5-0.1,0.7-0.2s0.5-0.4,0.6-0.6c0.1-0.2,0.2-0.6,0.2-0.9c0-0.4-0.1-0.6-0.2-0.9
	C677.4,199.8,677.2,199.6,677,199.5z M653.2,199.6c-0.2-0.2-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.4,0.5-0.5,0.9h2.7
	C653.6,200.1,653.5,199.8,653.2,199.6z M442.1,359.7c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
	c-0.1,0.2-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2
	c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8s0-0.6-0.2-0.8C442.5,360,442.3,359.8,442.1,359.7z M451,359.5
	c-0.4,0-0.6,0.1-0.9,0.4s-0.4,0.6-0.4,1.1s0.1,0.8,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5
	c0.1-0.2,0.2-0.5,0.2-0.8c0-0.5-0.1-0.8-0.4-1.1C451.6,359.6,451.4,359.5,451,359.5z M530.7,197.7c-0.2-0.1-0.4-0.2-0.6-0.2
	s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5
	c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.8s0-0.6-0.2-0.8
	C531.1,198,530.9,197.8,530.7,197.7z M467.9,362.3c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5
	c0.1-0.2,0.2-0.5,0.2-0.8s0-0.6-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2
	c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8C467.5,362,467.7,362.2,467.9,362.3z M468.9,300.3
	c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8c0-0.4-0.1-0.6-0.2-0.8
	c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8
	s0.1,0.6,0.2,0.8C468.5,300,468.7,300.2,468.9,300.3z M452,297.4c-0.4,0-0.6,0.1-0.9,0.4s-0.4,0.6-0.4,1.1s0.1,0.8,0.3,1.1
	c0.2,0.3,0.5,0.4,0.9,0.4c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8c0-0.5-0.1-0.8-0.4-1.1
	S452.4,297.4,452,297.4z M446.7,300.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8c-0.1-0.4-0.1-0.6-0.2-0.8
	c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8
	s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2S446.5,300.4,446.7,300.3z M889.8,102.9c-0.2-0.1-0.5-0.2-0.7-0.2
	s-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.6,0.6s-0.2,0.6-0.2,0.9c0,0.3,0.1,0.7,0.2,0.9c0.1,0.2,0.3,0.5,0.6,0.6
	c0.2,0.1,0.5,0.2,0.7,0.2s0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.3,0.6-0.6c0.1-0.2,0.2-0.6,0.2-0.9c-0.1-0.3-0.1-0.6-0.2-0.9
	C890.3,103.2,890,103,889.8,102.9z M760.2,136.8c-0.2-0.1-0.4-0.2-0.7-0.2s-0.4,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.6,0.6
	c-0.1,0.2-0.2,0.6-0.2,0.9s0.1,0.7,0.2,0.9c0.1,0.2,0.3,0.4,0.6,0.6s0.4,0.2,0.7,0.2c0.2,0,0.4-0.1,0.7-0.2s0.4-0.3,0.6-0.6
	c0.1-0.2,0.2-0.6,0.2-0.9s-0.1-0.6-0.2-0.9C760.7,137.2,760.5,137,760.2,136.8z M897.7,106.4c0.6,3,1.3,6.1,2,9.1
	c-23,8.7-101.3,37.9-137.5,48.2c-4.1,1.2-7.9,3-11.3,5.5c-32,23.4-83.3,50.2-131.1,69.8c-5.4,2.2-10.9,3.9-16.4,5.4
	c-19.4,5.2-35.5,13.3-48.2,21.7c-6.6,4.4-12.8,9.4-18.3,15c-4.2,4.2-8,8.7-11.5,13.5c-4.3,5.9-8,12.1-11.2,18.7c-1,2-1.9,4-2.8,5.9
	c-0.5,1.1-1,2.1-1.5,3.1c-1.4,2.7-2.5,5-3.4,6.6c-0.1,0.3-0.3,0.6-0.4,0.8c-0.3,0.5-0.5,0.9-0.6,1.1c-0.1,0.3-0.2,0.4-0.2,0.4
	s-2.7,5.3-6.6,14.8c-3.9,9.5-9,23.1-14,39.9c0,0,0,0,0,0.1c0,1.6-1,3.3-1.5,4.9c0,0.1-0.1,0.3-0.1,0.4c-0.5,1.6-1,3.2-1.4,4.9
	c-0.1,0.3-0.2,0.7-0.3,1c-0.4,1.4-0.8,2.8-1.2,4.3c-0.1,0.4-0.3,0.9-0.4,1.3c-0.4,1.4-0.8,2.8-1.1,4.3c-0.1,0.4-0.2,0.8-0.3,1.2
	c-0.4,1.6-0.9,3.3-1.3,5c-0.1,0.4-0.2,0.7-0.3,1c-0.4,1.7-0.8,3.3-1.2,5.1c-0.1,0.3-0.1,0.6-0.2,1c-0.4,1.8-0.8,3.5-1.2,5.3
	c0,0.2-0.1,0.5-0.2,0.7c-0.5,2-0.9,4-1.3,6.1c-0.5,2.2-0.8,4.4-1,6.7c-0.1,1.3-0.3,2.6-0.5,3.9c0,0.3-0.1,0.6-0.1,1
	c-0.2,1.2-0.3,2.4-0.5,3.6c0,0.3-0.1,0.5-0.1,0.8c-0.2,1-0.3,2-0.5,2.9c-0.1,0.3-0.1,0.6-0.2,1c-0.2,1.1-0.4,2.2-0.6,3.2
	c-0.1,0.3-0.2,0.7-0.2,1c-0.2,0.9-0.4,1.7-0.6,2.5c-0.1,0.3-0.1,0.6-0.2,0.8c-0.2,1-0.5,2-0.8,2.9c-0.1,0.4-0.2,0.7-0.3,1.1
	c-0.2,0.8-0.5,1.6-0.7,2.4c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.8-0.5,1.5-0.7,2.2c-0.1,0.3-0.2,0.7-0.3,1c-0.3,0.8-0.6,1.7-0.9,2.5
	c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.6-0.5,1.2-0.7,1.9c-0.1,0.3-0.2,0.6-0.4,0.9c-0.3,0.8-0.6,1.5-1,2.3c-0.1,0.3-0.3,0.6-0.4,0.9
	c-0.3,0.6-0.5,1.2-0.8,1.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.7-0.7,1.4-1,2.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.6-0.7,1.3-1,1.9
	c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.6-0.6,1.1-1,1.7c-0.1,0.2-0.3,0.4-0.4,0.6c-0.4,0.6-0.8,1.3-1.1,1.9c-0.1,0.2-0.2,0.3-0.3,0.5
	c-0.3,0.5-0.7,1-1,1.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0.6-0.8,1.2-1.2,1.7c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0.5-0.8,1.1-1.2,1.6
	l-0.1,0.1c-0.4,0.6-0.8,1.1-1.3,1.7c-0.1,0.1-0.1,0.2-0.2,0.2c-0.9,1.1-1.8,2.1-2.6,3.1c0,0,0,0-0.1,0.1
	c-8.4,9.5-15.6,12.9-15.6,12.9c-68.1,34.1-149,21.7-149,21.7c9.3-3.7,18.5-7,27.7-10.8c3.7-1.5,6.1-6,9-9.2c4-4.6,8-9,12.4-13.1
	c5.7-5.4,12-9.9,19.6-13.4c3.3-1.5,6.4-2.8,9.3-4.8h2.5c3.6,0,6.9-1.8,8.8-4.8l4.7-7.2h18.7l16.9-10.8c0,0,20.5-16.4,26.2-42.1
	c4.2-17.9,3.3-34.2,0.5-47.5c-3.7-3.9-6.5-6.7-6.8-7.4c-0.4-1.1-8.6-5.8-17.5-10.9c-0.7-0.4-1.4-0.8-2.1-1.2
	c0.2-10.2,0.4-24.4,0.6-34c0-1.1,0-2.4,0.5-3.3c6.7-10.8,12.9-22.1,20.5-32.3c7.1-9.4,14.3-17.5,11.6-31
	c-2.5-12.7,8.2-20.2,16.3-28.6c8.5-8.8,15.8-18.8,23.6-28.2c1.4-1.7,2.6-3.8,4.5-4.7c9.8-4.8,19.9-9.1,29.7-13.9
	c6.3-3.1,12.7-6.1,19.1-9.1c40.9-0.8,107.2-18.7,151.1-31.5c47.3-31.6,62.5-26.5,104.3-22.8c44.9,3.7,81.1-38.6,81.1-38.6
	s2-1.9,4.8-4.3c5.8,5,11.5,10.3,17.7,15.7c-1,2.1-2.4,4.7-4.1,8c2.3,3.1,4.8,6.5,7.3,9.9C899.5,106,898.6,106.2,897.7,106.4z
	M428.8,311.2c0-2.8-2.2-5-5-5s-5,2.2-5,5s2.2,5,5,5C426.6,316.2,428.8,314,428.8,311.2z M433,295.1h-3.6v6.2h3.6v-1h-2.3v-1.6h2.1
	v-1h-2.1v-1.5v-0.1h2.3V295.1z M378.1,482.9L378.1,482.9l1.6,0.1c-0.2-0.6-0.7-1.2-1.2-1.5s-1.1-0.5-1.9-0.5c-0.6,0-1.2,0.1-1.7,0.4
	c-0.6,0.2-0.9,0.7-1.2,1.2s-0.4,1.1-0.4,1.7c0,0.6,0.1,1.2,0.4,1.7c0.2,0.6,0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4c0.5,0,1.1-0.1,1.5-0.3
	c0.5-0.2,0.8-0.6,1.1-1c0.2-0.4,0.4-0.9,0.5-1.3v-1.2h-3.5v1h2.3c-0.1,0.5-0.3,0.8-0.6,1.1s-0.8,0.4-1.3,0.4s-0.8,0-1.1-0.2
	c-0.4-0.3-0.6-0.5-0.8-0.8c-0.1-0.3-0.2-0.8-0.2-1.2s0-0.9,0.2-1.2c0.3-0.4,0.5-0.6,0.8-0.8c0.3-0.1,0.7-0.2,1.1-0.2
	c0.5,0,0.7,0.1,1,0.2C377.9,482.2,378.1,482.4,378.1,482.9z M386.5,482.2l-1.2,0.1v2.7c0,0.4-0.1,0.7-0.3,0.9s-0.5,0.3-0.8,0.3
	s-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.9v-2.7h-1.2v2.9c0,0.4,0.1,0.8,0.3,1.1c0.2,0.3,0.4,0.5,0.7,0.7c0.3,0.1,0.6,0.2,1,0.2
	c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5v0.6h1.2V482.2z M392.1,484.8c0-0.5-0.1-1-0.3-1.4c-0.2-0.5-0.6-0.8-1-1
	c-0.3-0.2-0.8-0.3-1.3-0.3s-1,0.1-1.4,0.3c-0.5,0.2-0.8,0.6-1,1c-0.2,0.3-0.3,0.9-0.3,1.4s0.1,0.9,0.3,1.4s0.6,0.8,1,1
	c0.4,0.2,0.9,0.3,1.4,0.3c0.6,0,1.1-0.2,1.6-0.5s0.8-0.8,0.9-1.3h-1.4c-0.2,0.4-0.6,0.6-1.1,0.6c-0.4,0-0.7,0-0.9-0.3
	c-0.2-0.3-0.4-0.5-0.4-0.9h3.9C392.1,485,392.1,484.7,392.1,484.8z M394.3,480.3H393v7h1.3V480.3z M403.8,484.2
	c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.5-1.6-0.7c-0.4,0-0.7,0.1-1,0.3s-0.5,0.4-0.7,0.7c-0.2-0.3-0.4-0.5-0.7-0.7
	c-0.3-0.1-0.6-0.2-1-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.5v-0.6h-1.2v4.9h1.4v-2.7c0-0.4,0.1-0.7,0.3-0.9
	s0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.9v2.7h1.2v-2.7c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,1-0.3,1.3-0.3
	s0.1,0.1,0.3,0.3c0.2,0.2,0.3,0.5,0.3,0.9v2.7h1.2h0V484.2z M406.9,482h-1.3v5.3h1.3V482z M407,480.7c0-0.2-0.1-0.4-0.2-0.5
	c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5s0.1,0.4,0.2,0.5c0.1,0.2,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2
	C406.9,481.1,407,480.9,407,480.7z M417,484.2c0-0.6-0.2-1.1-0.6-1.6c-0.4-0.5-1-0.6-1.5-0.8c-0.5,0-0.8,0.1-1.1,0.3
	c-0.4,0.3-0.6,0.5-0.8,0.8c-0.3-0.4-0.5-0.6-0.8-0.8c-0.3-0.1-0.7-0.2-1.1-0.2c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.4,0.3-0.6,0.5V482
	h-1.3v5.3h1.3v-2.9c0-0.5,0.1-0.8,0.3-1c0.3-0.2,0.5-0.3,0.9-0.3c0.4,0,0.7,0.1,0.9,0.3s0.3,0.6,0.3,1v2.9h1.3v-2.9
	c0-0.5,0.1-0.8,0.3-1c0.3-0.2,0.6-0.3,0.9-0.3c0.4,0,0.7,0.1,0.9,0.3s0.3,0.6,0.3,1v2.9h1.3h0V484.2z M422,474.1
	c0-1.8-1.4-3.2-3.2-3.2s-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2C420.6,477.3,422,475.8,422,474.1z M466.9,299c0,0.5,0.1,0.9,0.3,1.3
	c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2v-4.9h-1.2v0.7
	c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.9C467.1,298,467,298.4,466.9,299z
	M466.3,359.7c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.8,0.9s0.7,0.3,1.1,0.3c0.4,0,0.7-0.1,1-0.2
	c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2v-4.9h-1.2v0.7c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3
	C466.8,359,466.5,359.3,466.3,359.7z M463.8,296.5v4.9h1.2V299c0-0.5,0.1-0.8,0.3-1c0.2-0.2,0.5-0.3,0.9-0.3h0.3v-1.3
	c-0.4,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7v-0.8H463.8z M461.1,295.2c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.3,0.2,0.5,0.2
	s0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2
	C461.2,294.8,461.1,295,461.1,295.2z M461.3,296.5v4.9h1.2v-4.9H461.3z M436.8,300c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.4,0.1-0.6,0.1
	s-0.4-0.1-0.6-0.2c-0.2-0.2-0.3-0.3-0.3-0.5h-1.2c0,0.3,0.1,0.5,0.3,0.8c0.2,0.3,0.5,0.5,0.8,0.6c0.3,0.2,0.7,0.3,1.1,0.2
	c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.1-0.3,0.2-0.5,0.2-0.8s-0.1-0.6-0.3-0.8s-0.4-0.3-0.6-0.4c-0.3-0.1-0.5-0.2-0.9-0.3
	c-0.4-0.1-0.7-0.2-0.8-0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0,0.6,0.2
	c0.1,0.2,0.2,0.3,0.2,0.5h1.2c-0.1-0.5-0.3-0.9-0.6-1.2c-0.4-0.3-0.8-0.4-1.4-0.4c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.7,0.5
	c-0.1,0.2-0.2,0.4-0.2,0.7s0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.6,0.4s0.5,0.2,0.9,0.3c0.4,0.1,0.6,0.2,0.8,0.3
	C436.7,299.7,436.8,299.8,436.8,300z M438.4,357.3H434v1h1.6v5.2h1.2v-5.2h1.6V357.3z M441.6,300c0,0.2-0.1,0.3-0.2,0.4
	c-0.2,0.1-0.4,0.1-0.6,0.1s-0.4-0.1-0.6-0.2c-0.2-0.2-0.3-0.3-0.3-0.5h-1.2c0,0.3,0.1,0.5,0.3,0.8c0.2,0.3,0.5,0.5,0.8,0.6
	c0.3,0.2,0.7,0.3,1.2,0.2c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.1-0.3,0.2-0.5,0.2-0.8s-0.1-0.6-0.3-0.8s-0.4-0.3-0.6-0.4
	c-0.3-0.1-0.5-0.2-0.9-0.3c-0.4-0.1-0.7-0.2-0.8-0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.5-0.1
	c0.3,0,0.5,0,0.6,0.2c0.1,0.2,0.2,0.3,0.2,0.5h1.1c-0.1-0.5-0.3-0.9-0.6-1.2c-0.4-0.3-0.8-0.4-1.4-0.4c-0.4,0-0.7,0.1-1,0.2
	c-0.3,0.1-0.5,0.3-0.7,0.5c-0.1,0.2-0.2,0.4-0.2,0.7s0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.6,0.4s0.5,0.2,0.9,0.3
	c0.4,0.1,0.6,0.2,0.8,0.3C441.5,299.7,441.6,299.8,441.6,300z M444.1,358.6h-1.2v0.7c-0.2-0.3-0.4-0.5-0.7-0.6
	c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3
	c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2V358.6z M444.6,301.2
	c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2v-4.9h-1.2v0.7c-0.2-0.3-0.4-0.5-0.7-0.6
	c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3
	S444.3,301,444.6,301.2z M448,358.5c-0.4,0-0.6,0.1-0.9,0.2c-0.2,0.2-0.4,0.4-0.6,0.7v-0.8h-1.2v4.9h1.2v-2.4c0-0.5,0.1-0.8,0.3-1
	c0.2-0.2,0.5-0.3,0.9-0.3h0.3h0V358.5z M453.5,361c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.8-0.3-1.3-0.3
	s-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,0.9
	c0.4,0.2,0.8,0.3,1.3,0.3s0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.9C453.4,361.9,453.5,361.5,453.5,361z M454.2,300.2
	c0.2-0.4,0.3-0.8,0.3-1.3s-0.1-0.9-0.3-1.3c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.8-0.3-1.3-0.3s-0.9,0.1-1.3,0.3
	c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.3,0.8,0.4,1.3,0.3
	c0.5,0,0.9-0.1,1.3-0.3C453.7,300.9,454,300.6,454.2,300.2z M459.1,363.5v-4.9l-1.2,0.1v2.7c0,0.4-0.1,0.7-0.3,0.9
	c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.9v-2.7h-1.2v2.9c0,0.4,0.1,0.8,0.3,1.1c0.2,0.3,0.4,0.5,0.7,0.7
	c0.3,0.1,0.6,0.2,1,0.2c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5v0.6H459.1z M460,296.5l-1.2,0.1v2.7c0,0.4-0.1,0.7-0.3,0.9
	s-0.5,0.3-0.8,0.3s-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.9v-2.7h-1.2v2.9c0,0.4,0.1,0.8,0.3,1.1s0.4,0.5,0.7,0.7
	c0.3,0.1,0.6,0.2,1,0.2c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5v0.6h1.2V296.5z M463.2,363.3c0.2-0.2,0.4-0.4,0.6-0.6v0.7
	h1.3v-6.5h-1.3v2.4c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.9
	c-0.2,0.4-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3C462.6,363.5,463,363.5,463.2,363.3z
	M474.5,377.1c0-3.1-2.6-5.7-5.7-5.7c-3.1,0-5.7,2.6-5.7,5.7c0,3.1,2.6,5.7,5.7,5.7C471.9,382.8,474.5,380.2,474.5,377.1z
	M476.9,360.5c0-0.6-0.1-1.1-0.5-1.5c-0.4-0.3-0.8-0.5-1.5-0.6c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.5v-0.6h-1.2v4.9h1.2
	v-2.7c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.9v2.7h1.2V360.5z M480.7,362.5h-0.6
	c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.4h1v-1h-1.1v-1.2h-1.2v1.2h-0.6v1h0.6v2.4c0,1,0.6,1.5,1.6,1.5h0.8h0V362.5z
	M503.2,195.4c0-2.9-2.3-5.2-5.2-5.2s-5.2,2.3-5.2,5.2c0,2.9,2.3,5.2,5.2,5.2C500.9,200.6,503.2,198.3,503.2,195.4z M511.2,195.1
	h-3.6v6.2h3.6v-1h-2.3v-1.6h2.1v-1h-2.1v-1.5l2.3-0.1V195.1z M513.6,194.9h-1.2v6.5h1.2V194.9z M520.5,195.2h-1.2v4.3
	c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.6h-1.2c0,0.6,0.1,1.1,0.5,1.4
	c0.3,0.3,0.8,0.5,1.4,0.5c0.6,0,1.1-0.2,1.4-0.5s0.5-0.8,0.5-1.4V195.2z M526.7,196.5h-1.2v0.7c-0.2-0.3-0.4-0.5-0.7-0.6
	c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3s-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3
	c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3s0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2V196.5z M532.7,194.9h-1.3v2.3
	c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.2c-0.4,0-0.8,0.1-1.1,0.3s-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.8-0.3,1.4
	c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3s0.8,0,1-0.2s0.4-0.4,0.6-0.6v0.7h1.3h0V194.9z
	M535.1,196.5h-1.2v4.9h1.2V196.5z M535.2,195.2c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2
	c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2C535.1,195.6,535.2,195.4,535.2,195.2z
	M541.1,194.9h-1.3v2.3c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.2c-0.4,0-0.8,0.1-1.1,0.3s-0.6,0.5-0.8,0.9
	c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3s0.8,0,1-0.2s0.4-0.4,0.6-0.6v0.7
	h1.3h0V194.9z M547.1,196.5h-1.2v0.7c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3s-0.6,0.5-0.8,0.9
	c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3s0.7-0.1,1-0.2
	c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2V196.5z M662.9,196.7c0,0.2,0.1,0.5,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2
	c0.1-0.1,0.2-0.4,0.2-0.6s-0.1-0.5-0.2-0.6c-0.1-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2C663,196.2,662.9,196.5,662.9,196.7z
	M663,198.2v5.8h1.4v-5.8H663z M656.1,203.9h1.4v-3.2c0-0.5,0.2-0.9,0.4-1.1c0.2-0.3,0.6-0.4,0.9-0.4s0.7,0.2,0.9,0.4
	c0.3,0.3,0.4,0.6,0.4,1.1v3.2h1.4v-3.4c0-0.7-0.1-1.3-0.6-1.8c-0.5-0.3-1.1-0.6-1.8-0.7c-0.3,0-0.7,0.1-0.9,0.2
	c-0.2,0.1-0.5,0.4-0.7,0.6v-0.7h-1.4h0V203.9z M642,203.8l-3.1-3.6l2.9-3.6H640l-2.6,3.3v-3.3H636v7.3h1.4v-3.2l2.6,3.2v-0.1H642z
	M648.2,203.8v-3.4c0-0.5,0-0.9-0.2-1.3c-0.2-0.3-0.4-0.6-0.8-0.8c-0.4-0.1-0.7-0.2-1.2-0.2c-0.4,0-0.9,0.1-1.1,0.2
	c-0.2,0.1-0.5,0.4-0.7,0.6v-2.7h-1.4v7.6h1.4v-3.2c0-0.5,0.2-0.9,0.4-1.1c0.2-0.3,0.6-0.4,0.9-0.4s0.7,0.2,0.9,0.4
	c0.3,0.3,0.4,0.6,0.4,1.1v3.2H648.2z M655.1,201.1c0-0.6-0.2-1-0.4-1.5c-0.3-0.5-0.6-0.9-1.1-1.1c-0.3-0.3-0.8-0.4-1.4-0.4
	s-1,0.1-1.5,0.4c-0.5,0.3-0.9,0.6-1.1,1.1c-0.3,0.3-0.4,0.9-0.4,1.5c0,0.6,0.2,1,0.4,1.5c0.3,0.5,0.6,0.9,1.1,1.1
	c0.4,0.3,0.9,0.4,1.5,0.4c0.7,0,1.3-0.2,1.8-0.6c0.4-0.3,0.8-0.8,0.9-1.4h-1.5c-0.3,0.5-0.6,0.7-1.2,0.7c-0.3,0-0.7-0.2-0.9-0.4
	c-0.3-0.2-0.5-0.4-0.5-0.9h4.2C655,201.2,655.1,201.1,655.1,201.1z M664.8,212.6c0-2.6-2.2-4.8-4.8-4.8s-4.8,2.1-4.8,4.8
	c0,2.6,2.1,4.8,4.8,4.8S664.8,215.3,664.8,212.6z M668.6,199.4l-0.1-1.2h-1.1V198c0-0.3,0.1-0.6,0.2-0.7c0.1-0.1,0.3-0.1,0.7-0.1
	V196c-0.7,0-1.4,0.1-1.8,0.5c-0.4,0.3-0.6,0.8-0.6,1.5v0.2h-0.7v1.2h0.7v4.6h1.5v-4.6H668.6z M672.5,199.6v-1.5
	c-0.5,0-0.7,0.1-1.1,0.2c-0.2,0.2-0.5,0.4-0.7,0.8v-0.9h-1.4v5.8h1.4v-2.8c0-0.6,0.2-1,0.4-1.2c0.3-0.3,0.6-0.4,1.1-0.4H672.5z
	M679.2,198h-1.4v0.8c-0.2-0.3-0.4-0.6-0.8-0.7s-0.7-0.2-1.2-0.2s-1,0.1-1.3,0.4c-0.3,0.3-0.7,0.6-0.9,1.1c-0.3,0.4-0.4,0.9-0.4,1.6
	c0,0.6,0.2,1,0.4,1.5s0.5,0.8,0.9,1.1s0.8,0.4,1.3,0.4s0.8-0.1,1.2-0.2c0.3-0.2,0.6-0.5,0.8-0.7v0.8h1.4V198z M689.3,143h-4.8v7.8
	h1.5v-3.3h2.5v-1.3H686v-2h3.3V143z M696.1,147.6c0-0.6-0.1-1.1-0.4-1.6c-0.2-0.5-0.6-0.8-1.1-1.1c-0.4-0.3-0.9-0.4-1.5-0.4
	s-1.1,0.1-1.6,0.4c-0.5,0.2-0.8,0.6-1.1,1.1c-0.3,0.3-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.4,1.6c0.2,0.5,0.6,0.8,1.1,1.1s1,0.4,1.6,0.4
	c0.8,0,1.4-0.2,1.9-0.6c0.5-0.4,0.9-0.9,1-1.5h-1.6c-0.3,0.5-0.7,0.8-1.3,0.8c-0.4,0-0.7-0.1-1-0.4c-0.2-0.2-0.5-0.5-0.5-1h4.5
	C696.1,147.8,696.2,147.7,696.1,147.6z M702.1,148.7c0-0.4-0.1-0.7-0.4-1c-0.3-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.3-1.1-0.4
	c-0.5-0.1-0.9-0.3-1-0.4c-0.2-0.1-0.3-0.2-0.3-0.5c0-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.6-0.1c0.4,0,0.7,0,0.8,0.3
	c0.2,0.2,0.3,0.3,0.3,0.6h1.5c-0.2-0.6-0.4-1.1-0.8-1.5c-0.5-0.4-1-0.5-1.8-0.5c-0.5,0-0.9,0.2-1.3,0.3c-0.4,0.1-0.6,0.3-0.9,0.6
	c-0.2,0.3-0.3,0.5-0.3,0.9c0,0.4,0.1,0.7,0.4,1c0.3,0.2,0.5,0.4,0.8,0.5c0.2,0.1,0.6,0.3,1.1,0.4s0.7,0.3,1,0.4
	c0.2,0.1,0.3,0.2,0.3,0.5c0,0.2-0.2,0.4-0.3,0.5c-0.3,0.1-0.5,0.1-0.8,0.1s-0.5-0.2-0.8-0.3c-0.3-0.2-0.4-0.3-0.4-0.6h-1.5
	c0,0.4,0.1,0.6,0.4,1c0.2,0.4,0.6,0.7,1,0.8c0.5,0.3,0.9,0.3,1.4,0.3s0.9-0.2,1.3-0.3c0.4-0.1,0.6-0.3,0.9-0.6
	C701.8,149.4,702.1,149.1,702.1,148.7z M709.8,136.3c0-2.8-2.3-5.1-5.1-5.1s-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1
	S709.8,139.1,709.8,136.3z M744.8,134.2h-5v1.1h1.9v5.8h1.3v-5.8h1.8V134.2z M751.1,135.7h-1.3v0.8c-0.2-0.4-0.5-0.6-0.8-0.7
	s-0.7-0.2-1.1-0.2s-0.9,0.1-1.2,0.3c-0.3,0.2-0.7,0.6-0.9,1c-0.2,0.4-0.3,0.8-0.3,1.5c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.6,0.8,0.9,1
	s0.8,0.3,1.2,0.3s0.8-0.1,1.1-0.2c0.4-0.3,0.6-0.5,0.8-0.7v0.8h1.3V135.7z M756,139.8h-2.4l2.3-3.2v-1.1H752v1.1h2.3l-2.3,3.2v1.1h4
	h0V139.8z M762.5,135.7h-1.3v0.8c-0.2-0.4-0.5-0.6-0.8-0.7s-0.7-0.2-1.1-0.2s-0.9,0.1-1.2,0.3c-0.3,0.2-0.7,0.6-0.9,1
	c-0.2,0.4-0.3,0.8-0.3,1.5c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.6,0.8,0.9,1s0.8,0.3,1.2,0.3s0.8-0.1,1.1-0.2c0.4-0.3,0.6-0.5,0.8-0.7v0.8
	h1.3V135.7z M769,125.7c0-2.6-2.1-4.7-4.7-4.7s-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7C766.9,130.4,769,128.3,769,125.7z
	M868.9,103.7c0-0.7-0.2-1.3-0.5-1.9s-0.8-0.9-1.3-1.3c-0.5-0.4-1.2-0.5-1.8-0.5s-1.2,0.2-1.8,0.5c-0.5,0.3-1,0.7-1.3,1.3
	c-0.4,0.6-0.5,1.2-0.5,1.9c0,0.6,0.2,1.2,0.5,1.8s0.8,0.9,1.3,1.3c0.5,0.4,1.2,0.5,1.8,0.5s1.3-0.2,1.8-0.5s1-0.7,1.3-1.3
	C868.8,104.9,868.9,104.4,868.9,103.7z M875.3,107.3v-5.6h-1.4v3.1c0,0.4-0.1,0.8-0.3,1s-0.6,0.3-0.9,0.3s-0.7-0.1-0.9-0.3
	s-0.3-0.5-0.3-1v-3.1h-1.4v3.4c0,0.5,0.1,1,0.3,1.3c0.2,0.3,0.5,0.6,0.8,0.8c0.4,0.1,0.7,0.2,1.2,0.2c0.3,0,0.7-0.1,0.9-0.2
	c0.2-0.1,0.5-0.4,0.7-0.6v0.7H875.3z M878.1,101.6h-1.4v6.6c0,0.3,0,0.4-0.1,0.5c-0.2,0.1-0.3,0.1-0.5,0.1h-0.5v1.3h0.6
	c0.6,0,1.1-0.3,1.4-0.5c0.4-0.4,0.5-0.8,0.5-1.4V101.6z M878.2,100.1c0-0.3-0.1-0.5-0.2-0.6s-0.4-0.2-0.6-0.2
	c-0.4,0-0.6,0.1-0.7,0.2c-0.1,0.1-0.2,0.3-0.2,0.6s0.1,0.5,0.2,0.6c0.2,0.1,0.4,0.2,0.7,0.2s0.5-0.1,0.6-0.2
	C878.1,100.5,878.2,100.4,878.2,100.1z M885,107.3v-7.4h-1.5v2.7c-0.2-0.4-0.5-0.6-0.8-0.7c-0.3-0.1-0.7-0.2-1-0.2
	c-0.5,0-1,0.1-1.3,0.3s-0.7,0.5-0.9,1c-0.2,0.4-0.3,0.9-0.3,1.6c0,0.6,0.1,1,0.3,1.5c0.2,0.4,0.6,0.8,0.9,1c0.4,0.2,0.8,0.3,1.3,0.3
	s1,0,1.2-0.2c0.2-0.2,0.5-0.5,0.7-0.7v0.8H885z M892,101.8h-1.4v0.8c-0.2-0.4-0.5-0.6-0.8-0.7c-0.4-0.1-0.7-0.2-1.2-0.2
	s-1,0.1-1.3,0.3s-0.7,0.5-0.9,1c-0.2,0.4-0.3,0.9-0.3,1.6c0,0.6,0.1,1,0.3,1.5c0.2,0.4,0.6,0.8,0.9,1c0.4,0.2,0.8,0.3,1.3,0.3
	s0.9-0.1,1.2-0.2c0.3-0.2,0.6-0.5,0.8-0.7v0.8h1.4V101.8z M893,94.7c0-2-1.7-3.7-3.7-3.7s-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7
	S893,96.7,893,94.7z M882.9,102.9c-0.2-0.1-0.5-0.2-0.7-0.2s-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.6,0.6c-0.1,0.2-0.2,0.6-0.2,0.9
	c0,0.3,0.1,0.7,0.2,0.9c0.1,0.2,0.3,0.5,0.6,0.6c0.2,0.1,0.5,0.2,0.7,0.2s0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.3,0.6-0.6
	c0.1-0.2,0.2-0.6,0.2-0.9c0-0.3-0.1-0.6-0.2-0.9C883.3,103.2,883.1,103,882.9,102.9z M866.3,101.6c-0.3-0.2-0.7-0.2-1.2-0.2
	s-0.8,0.1-1.2,0.2c-0.3,0.1-0.6,0.5-0.8,0.8c-0.2,0.3-0.2,0.8-0.2,1.3s0.1,0.9,0.2,1.3c0.1,0.3,0.5,0.7,0.8,0.8
	c0.3,0.2,0.7,0.3,1.2,0.3s0.9-0.2,1.2-0.3c0.3-0.2,0.6-0.5,0.8-0.8s0.2-0.8,0.2-1.3s-0.1-0.9-0.2-1.3
	C867,102.1,866.7,101.8,866.3,101.6z M539.2,197.7c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
	c-0.1,0.2-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
	c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.8s-0.1-0.6-0.2-0.8C539.6,198,539.4,197.8,539.2,197.7z"
          />
          <path
            :class="{ heavy: ZoneNumber === 1, st4: ZoneNumber !== 1 }"
            @click="selectZone(1)"
            dataZone="1"
            class="st4"
            d="M789.2,68.7c0.3,0.3,0.4,0.6,0.4,1.1c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.1-0.7,0.2
	c-0.4,0-0.7-0.1-0.9-0.4c-0.2-0.3-0.3-0.6-0.3-1.1s0.1-0.8,0.4-1.1s0.5-0.4,0.9-0.4S788.9,68.4,789.2,68.7z M823.8,83.3h-1.2v1.6
	h1.2c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0-0.3-0.1-0.5-0.3-0.6S824.1,83.3,823.8,83.3z M817,68.5
	c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5
	c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8s0-0.6-0.2-0.8
	C817.4,68.8,817.2,68.6,817,68.5z M799.2,68.2c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.4,0.8h2.3c0-0.3-0.1-0.6-0.3-0.8
	S799.5,68.2,799.2,68.2z M824.3,82.1c0.2-0.1,0.2-0.3,0.2-0.6s-0.1-0.4-0.2-0.6c-0.2-0.1-0.4-0.2-0.7-0.2h-1.1v1.6h1.1
	C823.9,82.3,824.1,82.2,824.3,82.1z M622.2,134c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
	s-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5
	c0.1-0.2,0.2-0.5,0.2-0.8c-0.1-0.4-0.1-0.6-0.2-0.8S622.4,134.1,622.2,134z M829.1,81.9c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.4,0.8
	h2.3c0-0.3-0.1-0.6-0.3-0.8S829.4,81.9,829.1,81.9z M881.3,74.3c-2.7,2.4-4.8,4.3-4.8,4.3c-0.1,0.1-8.5,9.8-21.8,19.3
	c-12.3,8.8-33.6,18.2-55.2,18.2c-1.8,0-3.7-0.1-5.5-0.2c-3.8-0.3-7.4-0.7-10.8-1c-9.6-1-18.5-1.8-26.4-1.8c-18.7,0-33,3.9-65.1,25.3
	c-69.9,20.3-124.9,33.5-153.5,31.1c12.4-6,25.6-13,37.4-19.9c8.6-5,16.3-12.9,21.6-21.2c16.8-25.9,29.7-53.7,38.6-83.2
	c2.8-9.4,5.8-18.7,9.3-27.9c0.8-2.1,3.8-4.1,6.3-5c3.7-1.3,7.5-2.3,11.3-3.4c2.3,21.2,8,56.3,21.8,68.3c35.2,15.7,65.8-5.3,65.8-5.3
	s3.4-7.7,8.5-18.5c3.2-0.4,6.9,0.8,10.1,0.3c3.8-0.5,7.3-2.3,12.1-4c16.1,9.6,32.7,7.7,43.3-11.8c0.4,3.2,0.5,5.4,0.9,7.5
	c3.2,16.1,13.3,20.9,29.7,15.5c4-1.3,10.4-0.1,13.8,2.5C872.1,66,878,71.3,881.3,74.3z M598,141.7c0-2-1.7-3.7-3.7-3.7
	s-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7S598,143.7,598,141.7z M606.3,137.8l-1.5-2.5c0.5-0.1,0.9-0.4,1.1-0.7c0.3-0.3,0.4-0.7,0.4-1.1
	c0-0.4-0.1-0.7-0.3-1s-0.5-0.5-0.8-0.7s-0.7-0.3-1.2-0.3h-2.3v6.2h1.2v-2.4h0.6l1.4,2.4v0.1H606.3z M612.1,132.8h-1.2v0.7
	c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3s-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.8-0.3,1.4
	c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.8,0.9s0.7,0.3,1.1,0.3s0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2V132.8z
	M618.4,135.2c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.5-0.7-0.8-0.9c-0.3-0.2-0.7-0.3-1.1-0.3s-0.7,0.1-1,0.2c-0.3,0.2-0.5,0.4-0.7,0.6
	v-2.4h-1.2v6.5h1.2v-0.7c0.2,0.3,0.4,0.5,0.7,0.6c0.3,0.1,0.6,0.2,1,0.2c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.5,0.8-0.9
	C618.3,136.1,618.4,135.7,618.4,135.2z M624.1,132.8h-1.2v0.7c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2
	c-0.4,0-0.8,0.1-1.1,0.3s-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.8,0.9s0.7,0.3,1.1,0.3
	s0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2V132.8z M627.8,136.7h-0.6c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.4h1v-1
	h-1.1v-1.2h-1.2v1.2h-0.6v1h0.6v2.4c0,1,0.6,1.5,1.6,1.5h0.8V136.7z M768.3,62.4c2,0,3.7-1.7,3.7-3.7s-1.7-3.7-3.7-3.7
	s-3.7,1.7-3.7,3.7S766.3,62.4,768.3,62.4z M774.1,66h-3.6v6.2h3.6v-1h-2.3v-1.6h2.1v-1h-2.1v-1.5l2.3-0.1V66z M776.5,65.7h-1.2v6.5
	h1.2V65.7z M784.9,72.3v-6.2h-1.3v2.5H781v-2.5h-1.2v6.2h1.2v-2.6h2.6v2.6H784.9z M790.8,69.8c0-0.5-0.1-0.9-0.3-1.3
	c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.8-0.3-1.3-0.3s-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3
	c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.8,0.3,1.3,0.3s0.9-0.1,1.3-0.3s0.7-0.5,0.9-0.9C790.7,70.7,790.8,70.3,790.8,69.8z M792.8,69.9
	c0-0.5,0.1-0.8,0.3-1.1s0.4-0.4,0.8-0.4c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5h1.3c-0.1-0.6-0.4-1-0.8-1.3
	s-0.9-0.5-1.5-0.5c-0.5,0-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.3-0.3,1-0.3,1.5s0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,0.9
	c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.8,0.8-1.3h-1.3c-0.2,0.5-0.5,0.7-1,0.7c-0.3,0-0.6-0.1-0.8-0.4
	C792.9,70.7,792.8,70.4,792.8,69.9z M801.6,69.9c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.5-0.7-0.9-0.9c-0.3-0.2-0.7-0.3-1.2-0.3
	s-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.3-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,0.9
	c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.7,0.8-1.2h-1.3c-0.2,0.4-0.5,0.6-1,0.6c-0.3,0-0.6-0.1-0.8-0.3
	s-0.4-0.4-0.4-0.8h3.6C801.6,70,801.6,69.9,801.6,69.9z M803.7,67.3h-1.2v4.9h1.2V67.3z M803.8,66.1c0-0.2-0.1-0.4-0.2-0.5
	c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2
	s0.4-0.1,0.5-0.2C803.7,66.5,803.8,66.3,803.8,66.1z M813.1,69.3c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.3-0.9-0.5-1.4-0.7
	c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.1-0.6-0.2-1-0.2c-0.3,0-0.6,0.1-0.8,0.2
	c-0.2,0.1-0.4,0.3-0.6,0.5v-0.6h-1.2v4.9h1.2v-2.7c0-0.4,0.1-0.7,0.3-0.9s0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3s0.3,0.5,0.3,0.9v2.7h1.2
	v-2.7c0-0.4,0.1-0.7,0.3-0.9s0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3s0.3,0.5,0.3,0.9v2.7h1.2h0V69.3z M819,67.4h-1.2v0.7
	c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3s-0.6,0.5-0.8,0.9c-0.2,0.3-0.3,0.8-0.3,1.4
	c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3s0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2V67.4z
	M825.9,84.3c0-0.4-0.1-0.7-0.3-1s-0.5-0.5-1-0.5c0.4-0.1,0.6-0.3,0.8-0.5s0.3-0.5,0.3-0.9c0-0.3,0-0.6-0.2-0.8
	c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.7-0.2-1.1-0.2h-2.5V86h2.7c0.4,0,0.8-0.1,1.1-0.2c0.3-0.2,0.5-0.3,0.7-0.6
	C825.8,84.9,825.9,84.6,825.9,84.3z M831.5,83.5c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.5-0.7-0.9-0.9c-0.3-0.2-0.7-0.3-1.2-0.3
	s-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.3-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.8,0.3,1.3,0.3
	c0.6,0,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.7,0.8-1.2h-1.3c-0.2,0.4-0.5,0.6-1,0.6c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.4-0.4-0.4-0.8h3.6
	C831.5,83.7,831.5,83.5,831.5,83.5z M835.2,81c-0.4,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.6,0.6V81h-1.2v4.9h1.2v-2.3
	c0-0.5,0.1-0.8,0.3-1c0.2-0.2,0.5-0.3,0.9-0.3h0.3V81z M840.4,85.9l-2.2-2.4l2.2-2.5h-1.6l-1.6,2.1v-3.7H836v6.5h1.2v-2.1l1.7,2.1
	H840.4z M845.9,81h-1.2v0.7c-0.2-0.3-0.4-0.5-0.7-0.6s-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.9
	c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,0.9,0.3,1.3s0.5,0.7,0.8,0.9s0.7,0.3,1.1,0.3s0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6v0.7h1.2
	V81z M851.7,83c0-0.6-0.1-1.1-0.5-1.5c-0.3-0.4-0.8-0.6-1.5-0.6c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.5V81h-1.2v4.9h1.2
	v-2.7c0-0.4,0.1-0.7,0.3-0.9s0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3s0.3,0.5,0.3,0.9v2.7h1.2V83z M857.5,83.5c0-0.5-0.1-0.9-0.3-1.3
	c-0.2-0.4-0.5-0.7-0.9-0.9c-0.3-0.2-0.7-0.3-1.2-0.3s-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.3-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3
	c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.7,0.8-1.2h-1.3c-0.2,0.4-0.5,0.6-1,0.6
	c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.4-0.4-0.4-0.8h3.6C857.5,83.7,857.5,83.5,857.5,83.5z M868,77.7c0-2-1.7-3.7-3.7-3.7
	s-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7S868,79.7,868,77.7z M855.1,81.9c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.4,0.8h2.3
	c0-0.3-0.1-0.6-0.3-0.8S855.4,81.9,855.1,81.9z M604.7,132.8c-0.2-0.2-0.4-0.2-0.8-0.2h-1v1.8h1c0.3,0,0.6-0.1,0.8-0.2
	c0.2-0.2,0.2-0.4,0.2-0.7S604.8,133,604.7,132.8z M610.2,134c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
	s-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5
	c0.1-0.2,0.2-0.5,0.2-0.8c0-0.4-0.1-0.6-0.2-0.8S610.4,134.1,610.2,134z M616.5,134c-0.2-0.1-0.4-0.2-0.6-0.2
	c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5
	c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8c0-0.4-0.1-0.6-0.2-0.8
	C616.9,134.3,616.7,134.1,616.5,134z M844,82.2c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
	c-0.1,0.2-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
	c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.8s-0.1-0.6-0.2-0.8S844.2,82.3,844,82.2z M431.4,366.7c-0.2-0.6-2.6-2.2-6.2-4.3
	c3.5,7.2,6.8,15.1,6.9,16.5c1,11.4,3.3,19.6,0.8,30.8c-3.2,13.8-6,28.2-17,37.8c-5.3,4.7-11.1,8.9-17.5,12.2l-20.9,11
	c-0.1,0.2-0.2,0.4-0.3,0.7c-0.5,1-1.1,1.9-1.7,2.8c-1.5,2.3-2.7,4.5-4,6.3h0.5l3.9-6h18.7l16.9-10.8c0,0,20.5-16.4,26.2-42.1
	c4.2-17.9,3.3-34.2,0.5-47.5C434.5,370.2,431.7,367.4,431.4,366.7z"
          />
        </svg>
      </div>
    </div>
  </main>
</template>

<script>
import menuZone from './menuZone.vue'
import { CloseOutlined } from '@ant-design/icons-vue'
export default {
  props: ['ZoneNumber', 'keyCity', 'openKeys'],
  emits: ['numberRegion', 'closeZone', 'dataZon'],
  components: {
    menuZone,
    CloseOutlined,
  },

  data() {
    return {
      withScreen: null,
      selectedRegion: 1,
      keyVl: null,
      openKey: null,
      isActive: false,
      zoneData: [
        {
          id: 1,
          pes_hiver: 3,
          pes_ete: 7,
          pes_avg: 0,
        },
        {
          id: 2,
          pes_hiver: 3.6,
          pes_ete: 7.3,
          pes_avg: 0,
        },
        {
          id: 3,
          pes_hiver: 3.5,
          pes_ete: 7.6,
          pes_avg: 0,
        },
        {
          id: 4,
          pes_hiver: 3,
          pes_ete: 7.4,
          pes_avg: 0,
        },
        {
          id: 5,
          pes_hiver: 3,
          pes_ete: 7,
          pes_avg: 0,
        },
      ],
    }
  },
  methods: {
    selectZone(vl) {
      this.selectedRegion = vl
      this.$emit('numberRegion', this.selectedRegion)

      const dataZon = this.zoneData.find((el) => this.selectedRegion === el.id)
      if (vl && dataZon && this.keyVl && this.openKey) {
        this.$emit('dataZon', {
          dataZon,
          keyVl: this.keyVl,
          openKey: this.openKey,
        })
      } else {
        this.$emit('dataZon', {
          dataZon,
          keyVl: 0,
          openKey: [`sub${dataZon.id}`],
        })
      }

      // this.closeZone(false)
    },
    closeZone(vl) {
      this.$emit('closeZone', vl)
    },
    updateZon(vl) {
      this.keyVl = vl.selectCity
      this.openKey = vl.openKeys
      this.selectZone(vl.id)
      this.closeZone(false)
    },
    mediaMobaile() {
      const WidthScreen = window.document.querySelector('body').scrollWidth
      this.withScreen = WidthScreen
    },
  },
  created() {
    this.mediaMobaile()
    window.addEventListener('resize', this.mediaMobaile)
  },
}
</script>

<style scoped>
.ZonHeader {
  background: #ffffff;
  border: 0.916182px solid #e6e6e6;
  border-radius: 8px;
  color: #000;
  text-align: center;
  padding: 10px 26px;
  margin-bottom: 20px;
}
.svg-container {
  height: 450px;
  width: 450px;
  background-color: #ffffff;
  border: 0.916182px solid #e6e6e6;
  border-radius: 7.00338px;
}
.svg-container svg {
  transform: scale(0.9) translateX(10px);
}
h2 {
  color: #000;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 18.9741px;
}
.st0 {
  fill: #ffad02;
  background-color: #ffad02;
}

.st1 {
  fill: #e0dfd7;
}
.st2 {
  fill: #bda400;
}
.st3 {
  fill: #efc30a;
}
.st4 {
  fill: #c9c49f;
}
.heavy {
  fill: #e22623 !important;
}
.MenuZon {
  background-color: white;
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 0.916182px solid #e6e6e6;
  border-radius: 7.32945px;
}
.MenuZon::-webkit-scrollbar {
  width: 11px;
  scrollbar-width: thin;
  scrollbar-color: #e6e6e6;
}
.MenuZon::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 0 7px 7px 0;
}
.MenuZon::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 6px;
  border: 1px solid #d7d7d7;
}

.st0:hover,
.st1:hover,
.st2:hover,
.st3:hover,
.st4:hover {
  fill: #e226239b;
}

.Zone-icon {
  background-color: white;
}

.custom-slick-arrow {
  position: absolute;
  top: -27px;
  right: -27px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  z-index: 1;
  color: #ffffff;
  background-color: #e22623;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  filter: drop-shadow(0px 3.66473px 7.32945px rgba(226, 38, 35, 0.4));
  cursor: pointer;
}

.custom-slick-arrow :deep(.slick-arrow.custom-slick-arrow) {
  width: 40px;
  height: 40px;
  font-size: 35px;
  opacity: 1;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #e22623;
}
.zoneNAme {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media screen and (max-width: 500px) {
  .Zone {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-47%, -49%) scale(0.8);
    z-index: 200;
    background: #f5f5f5;
    border: 0.916182px solid #e6e6e6;
    box-shadow: 0px 0px 17px rgb(0 0 0 / 40%);
    border-radius: 8.24564px;
    width: 258px;
    height: 88%;
    padding: 30px;
  }
  .custom-slick-arrow {
    width: 44px;
    height: 44px;
    font-size: 23px;
  }
  .MenuZon {
    height: 70vh;
  }
}
</style>
