<template>
  <a-table
    :columns="columns"
    :data-source="data2"
    :pagination="false"
    @resizeColumn="handleResizeColumn"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'Les appareils'">
        <img width="40" height="40" :src="images[record.idImag]" />
      </template>
      <template v-else-if="column.key === 'Unités'">
        <span>
          {{ record.count }}
        </span>
      </template>
      <template v-else-if="column.key === 'Heures/j'">
        <span>
          {{ record.hours }}
        </span>
      </template>
      <template v-else-if="column.key === 'Watt'">
        <span>
          <a>{{ record.energy }}</a>
        </span>
      </template>
      <template v-else-if="column.key === 'Wh/j'">
        <span>
          <a>{{ record.hours * record.energy * record.count }}</a>
        </span>
      </template>
    </template>
  </a-table>
</template>

<script>
import lamp from '../../../assets/IMAGE/lamp.png'
import tv from '../../../assets/IMAGE/tv.png'
import mobile from '../../../assets/IMAGE/mobile.png'
import laptop from '../../../assets/IMAGE/laptop.png'
import refrigerator from '../../../assets/IMAGE/refrigerator.png'
import Mixeur from '../../../assets/IMAGE/blender.png'
import Auter from '../../../assets/IMAGE/other.png'

export default {
  components: {},
  data() {
    return {
      images: [lamp, tv, mobile, laptop, refrigerator, Mixeur, Auter],

      data2: JSON.parse(localStorage.listItems),
      columns: [
        {
          title: 'Les appareils',
          dataIndex: 'Les appareils',
          key: 'Les appareils',
          resizable: false,
          width: 150,
        },
        {
          title: 'Unités',
          key: 'Unités',
          resizable: false,
          width: 150,
        },
        {
          title: 'Heures/j',
          dataIndex: 'Heures/j',
          key: 'Heures/j',
          width: 150,
        },
        {
          title: 'Watt',
          key: 'Watt',
          dataIndex: 'Watt',
          width: 150,
        },
        {
          title: 'Wh/j',
          key: 'Wh/j',
          width: 150,
        },
      ],
    }
  },
  methods: {
    handleResizeColumn: (w, col) => {
      col.width = w
    },
  },
}
</script>

<style>
.img-appareils {
  width: 50 !important;
  height: 50 !important;
}
</style>
