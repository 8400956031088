<template>
  <div class="ant-carousel">
    <div class="ant-carousel_content">
      <div class="btn-col">
        <LeftOutlined
          class="custom-slick"
          @click="left"
          v-if="this.numberKit !== 0"
        />
        <span class="btn-col-span" @click="left" v-if="this.numberKit !== 0"
          >KIT MOINS PUISSANT
        </span>
      </div>

      <div class="layersfix">
        <div class="layers">
          <div
            id="imageKits"
            class="image_kit"
            v-if="this.KitsItem.length !== 0"
          >
            <!-- v-if="this.KitsItem.length !== 0" -->
            <a-image
              id="imageKits"
              :width="262.69"
              :height="262.69"
              :src="imageKits"
              @click="changUrlImg(this.KitsItem[this.numberKit].images[0].src)"
            />
          </div>
        </div>
        <!-- v-if="this.KitsItem.length === 0" -->

        <div class="custom_position" v-if="this.KitsItem.length === 0">
          <loading-outlined class="custom-slick-1" />
        </div>
      </div>

      <div class="btn-col">
        <RightOutlined
          class="custom-slick"
          @click="right"
          v-if="iconShowRight"
        />

        <span class="btn-col-span" @click="right" v-if="iconShowRight"
          >KIT PLUS PUISSANT</span
        >
      </div>
      <!--  -->
    </div>
    <div
      class="nameKit"
      @click="openInNewTab(this.KitsItem[this.numberKit].permalink)"
      v-if="withScreen <= 777 && this.KitsItem.length !== 0"
    >
      <span style="text-decoration-line: underline">
        {{ nameKit }}
      </span>
    </div>

    <div class="link_name" v-if="withScreen > 777">
      <span
        @click="openInNewTab(this.KitsItem[this.numberKit].permalink)"
        v-if="this.KitsItem.length !== 0"
        style="text-decoration-line: underline"
      >
        {{ nameKit }}
      </span>
      <div class="praice_kit" v-if="this.KitsItem.length !== 0">
        <span style="font-weight: 400; font-size: 13px">
          <b
            style="
              text-decoration-line: line-through;
              font-style: normal;
              font-weight: 400;
            "
          >
            {{ price.toFixed(0) }}</b
          >
          Dhs</span
        >

        <span style="margin: 2px 0" v-if="this.KitsItem.length !== 0">
          <b style="font-size: 26.4328px; font-weight: 600; color: #ffb800">
            {{ this.KitsItem[this.numberKit].price }}
          </b>
          Dhs
        </span>
        <span
          style="font-style: normal; font-weight: 400; font-size: 14px"
          v-if="this.KitsItem.length !== 0"
          >(Economisez 5%)</span
        >
      </div>

      <div class="btn-A-P">
        <button
          class="btn_Acheter"
          @click="AddToCard(this.KitsItem[this.numberKit].id)"
          v-if="this.KitsItem.length !== 0"
        >
          Acheter maintenant
        </button>
      </div>

      <div v-if="this.KitsItem.length === 0">
        <!-- loading data -->
        <span style="color: #ffb800">loading data</span>
        <loading-outlined
          style="color: #ffb800; width: 50px; height: 50px; font-size: 17px"
        />
        <!-- ------------- -->
      </div>
    </div>
  </div>
  <!-- /////////////////////////// -->
  <div style="display: flex; justify-content: center">
    <div
      class="contnet-nameKite2"
      v-if="withScreen <= 777 && this.KitsItem.length !== 0"
    >
      <div>
        <div class="praice_kit" v-if="this.KitsItem.length !== 0">
          <span style="font-weight: 400; font-size: 13px">
            <b
              style="
                text-decoration-line: line-through;
                font-style: normal;
                font-weight: 500;
              "
            >
              {{ price.toFixed(0) }}</b
            >
            Dhs</span
          >

          <span style="margin: 2px 0" v-if="this.KitsItem.length !== 0">
            <b style="font-size: 26.4328px; font-weight: 600; color: #ffb800">
              {{ this.KitsItem[this.numberKit].price }}
            </b>
            <b style="font-size: 14.5px"> Dhs</b>
          </span>
          <span
            style="font-style: normal; font-weight: 500; font-size: 14px"
            v-if="this.KitsItem.length !== 0"
            >(Economisez 5%)</span
          >
        </div>
      </div>
      <div class="btn-A-P">
        <button
          class="btn_Acheter"
          @click="AddToCard(this.KitsItem[this.numberKit].id)"
          v-if="this.KitsItem.length !== 0"
        >
          Acheter maintenant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from '@ant-design/icons-vue'
import { nextTick } from 'vue'
export default {
  props: ['KitsItem'],
  emits: ['PuissancePanneau'],
  components: {
    LeftOutlined,
    RightOutlined,
    LoadingOutlined,
  },
  data() {
    return {
      numberKit: JSON.parse(localStorage.kitNumber),
      iconShowLeft: true,
      iconShowRight: true,
      animation1: true,
      animation2: false,
      withScreen: null,
      widthToHiden: 777,
    }
  },
  computed: {
    imageKits() {
      const vl = `${this.KitsItem[this.numberKit].images[0].src}`
      return vl.replace('.jpg', '-300x300.jpg')
    },
    nameKit() {
      return `${this.KitsItem[this.numberKit].name}`
    },
    price() {
      const clprice = +this.KitsItem[this.numberKit].price
      return (clprice * 105.27) / 100
    },
  },
  watch: {
    KitsItem() {
      this.numberKit = JSON.parse(localStorage.kitNumber)
      this.sendData(this.numberKit)
      this.iconShowRight = true
    },
  },
  methods: {
    left() {
      if (this.numberKit === 0) {
        this.numberKit = 0
        this.iconShowLeft = false
      } else {
        this.iconShowLeft = true
        this.iconShowRight = true
        this.numberKit = this.numberKit - 1
        this.Animation()
      }
      localStorage.setItem('kitNumber', JSON.stringify(this.numberKit))
      this.sendData(this.numberKit)
    },
    right() {
      if (this.numberKit === this.KitsItem.length - 1) {
        this.iconShowRight = false
      } else {
        this.iconShowRight = true
        this.iconShowLeft = true
        this.numberKit = this.numberKit + 1
        this.Animation()
      }
      localStorage.setItem('kitNumber', JSON.stringify(this.numberKit))
      this.sendData(this.numberKit)
    },
    sendData(vl) {
      const itme = this.KitsItem
      const data = {
        panneau: +itme[vl].attributes[1].options[0],
        onduleur: +itme[vl].attributes[0].options[0],
        battre: +itme[vl].attributes[2].options[0],
      }
      this.$emit('PuissancePanneau', data)
    },
    Animation() {
      this.animation1 = true
      setTimeout(() => {
        this.animation1 = false
      }, 500)

      this.Animation2()
    },
    Animation2() {
      this.animation1 = false
      setTimeout(() => {
        this.animation1 = true
      }, 1000)
    },
    async changUrlImg(vl) {
      await nextTick()
      let img = await document
        .querySelector('.ant-image-preview-body')
        .querySelector('.ant-image-preview-img')
      const el = `${vl}`

      img.src = el.replace('-300x300.jpg', '.jpg')
    },
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer')
    },
    AddToCard(id) {
      const url = `https://cptechmaroc.ma/checkout/?add-to-cart=${id}`
      window.open(url, '_blank', 'noreferrer')
    },
    mediaMobaile() {
      const WidthScreen = window.document.querySelector('body').scrollWidth
      this.withScreen = WidthScreen
    },
  },
  created() {
    this.mediaMobaile()
    window.addEventListener('resize', this.mediaMobaile)
  },
}
</script>

<style scoped>
/* For demo */
.ant-carousel,
.ant-carousel_content {
  display: flex;
  text-align: center;
  height: 240px;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 0px;
}
.ant-carousel {
  height: 345.43px;
  padding: 15.5506px 57.7421px;
  border-radius: 0 0 10px 10px;
  border: 2px #e6e6e6 solid;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.07);
  background: #f6f8f9;
}
.custom-slick {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f6f8f9;
  font-size: 15px;
  font-weight: 700;
  width: 33.65px;
  height: 33.65px;
  background-color: #e22623;
  border-radius: 50%;
  box-shadow: 1px 1px 8px #e2262390;
}
.custom-slick-1 {
  color: #e22623;
  font-size: 50px;
}
.header_kit > span {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
#imageKits {
  background-color: #ffffff;
  border-radius: 10px;
  width: 262.69px;
  height: 262.69px;
}
.image_kit > span {
  margin-top: 20px !important;
}
.nameKit {
  width: 307px;
  font-weight: 600;
  cursor: pointer;
}
.btn-col {
  width: 90px;
  height: 100px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
}
.btn-col-span {
  color: #494949;
  font-size: 12.1525px;
  font-weight: 500;
}

.layersfix {
  position: relative;
  width: 262.69px;
  height: 262.69px;
  background-color: #f6f8f9 !important;
  border-radius: 10px;
}
.layers {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.5s;
}
.custom_position {
  position: absolute;
  top: 39%;
  left: 42%;
}
.link_name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 271.18px;
  height: 263.25px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px #e6e6e6 solid;
  padding: 14px;
  transform: translateX(-58px);
}
.praice_kit {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  align-items: center;
}
.link_name span {
  font-weight: 500;
  font-size: 15.2948px;
  line-height: 163%;
  text-align: center;
  color: #141414;
  cursor: pointer;
}

.btn-A-P {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.btn_Acheter {
  background: #ffb800;
  box-shadow: 0px 2.06558px 8.26232px rgba(255, 184, 0, 0.35);
  padding: 12.3935px 22.7214px;
  margin: 0 22px;
  border-radius: 5.16395px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.contnet-nameKite2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 479px;
  margin: 20px 0 0 0;
  background-color: #f8f8f8;
  padding: 10px 23px;
  border: 1px solid #ececec;
  border-radius: 8px;
}
.contnet-nameKite2 button {
  margin: 0;
}

/* ///////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////// */

.fadeIn2 {
  animation-name: fadeIn2;
  animation-duration: 1s;
}

.fadeOut2 {
  position: absolute;
  right: 15rem;
  animation-name: fadeOut2;
  animation-duration: 0.5s;
}

@keyframes fadeIn2 {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut2 {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.5);
  }
}
/* ////////////////////////////////////////// */

.fadeOut3 {
  animation-name: fadeOut3;
  animation-duration: 0.5s;
}

@keyframes fadeOut3 {
  0% {
    opacity: 1;
    transform: scale(1);
    transform: translateX(0px);
  }

  100% {
    opacity: 0;
    transform: scale(0);
    transform: translateX(-220px);
  }
}
/*ant-image */

/* ////////////////// media ///////////////////////////// */
@media screen and (max-width: 500px) {
  #imageKits {
    width: 202px !important;
    height: 202px !important;
  }
  .image_kit div {
    width: 202px !important;
    height: 202px !important;
  }
  .layersfix {
    position: relative;
    width: 202px;
    height: 202px;
    background-color: #f6f8f9 !important;
    border-radius: 10px;
  }
  .ant-carousel {
    height: 315.43px !important;
    padding: 12px;
    border-radius: 0 0 10px 10px;
    border: 2px #e6e6e6 solid;
    box-shadow: 1px 1px 8px rgb(0 0 0 / 7%);
    background: #f6f8f9;
  }
  .ant-carousel_content {
    padding: 1px;
  }
  .btn-col {
    width: 61px;
  }
  .ant-carousel_content {
    gap: 6px;
  }

  .ant-carousel_content {
    display: flex;
    text-align: center;
    height: 240px;
    align-items: center;
    padding: 15px;
    justify-content: center;
  }
}
/* 872 */
@media screen and (max-width: 909px) {
  .ant-carousel {
    padding: 14px 8px;
  }
}
@media screen and (max-width: 810px) {
  .link_name {
    transform: translateX(-27px);
  }
}
/* 775 */
@media screen and (max-width: 778px) {
  .ant-carousel_content {
    display: flex;
    text-align: center;
    height: 322px;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
  .ant-carousel {
    display: flex;
    flex-direction: column;
    gap: 1px;
    height: 379.43px;
  }
}
</style>
// //////////////////////////////////////////////////////////////////////// //
// /////////////////////////////////////////////////////////////////////// //
<style>
.ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 10px;
  border: 2px solid #e2e2e2;
}
.ant-image-mask {
  border-radius: 10px;
  backdrop-filter: blur(7.5px);
}

@media screen and (max-width: 500px) {
  #imageKits {
    width: 202px !important;
    height: 202px !important;
  }
  .image_kit div {
    width: 202px !important;
    height: 202px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layersfix {
    position: relative;
    width: 202px;
    height: 202px;
    background-color: #f6f8f9 !important;
    border-radius: 10px;
  }
  .ant-carousel {
    height: 345.43px;
    padding: 1px;
    border-radius: 0 0 10px 10px;
    border: 2px #e6e6e6 solid;
    box-shadow: 1px 1px 8px rgb(0 0 0 / 7%);
    background: #f6f8f9;
  }
  .ant-image-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }
}
</style>
