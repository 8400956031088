<template>
  <div class="svg-ete">
    <img :src="hiver" class="svg-ete" />
  </div>
</template>

<script>
import Hiver from './PngIcon/iconHiver.svg'
export default {
  data() {
    return {
      hiver: Hiver,
    }
  },
}
</script>

<style scoped>
.svg-ete {
  width: 37px;
  height: 37px;
}
@media screen and (max-width: 909px) {
  .svg-ete {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width: 402px) {
  .svg-ete {
width: 30px;
    height: 30px;
  }
}
</style>
