<template>
  <div>
    <img class="svg-ete" :src="onduleur" />
  </div>
</template>

<script>
import Onduleur from './PngIcon/onduleur.png'
export default {
  data() {
    return {
      onduleur: Onduleur,
    }
  },
}
</script>

<style scoped>
.svg-ete {
  height: 38px;
  width: 38px;
}
</style>
