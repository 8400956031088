<template>
  <div>
    <img class="svg-ete" :src="onduleur" />
  </div>
</template>

<script>
import Onduleur from './PngIcon/onduleur.svg'
export default {
  data() {
    return {
      onduleur: Onduleur,
    }
  },
}
</script>

<style scoped>
.svg-ete {
  height: 40px;
  width: 40px;
}
@media screen and (max-width: 909px) {
  .svg-ete {
    width: 35px;
    height: 35px;
  }
}
</style>
