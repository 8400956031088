<template>
  <div class="main_rull">
    <div style="margin: 12px 0">
      <h3 style="margin-bottom: 26px; font-size: 15px">PRODUCTION DU KIT</h3>
    </div>
    <div class="Production">
      <span>
        <div
          :class="{
            Wh_C_NOK: Wh_C_E <= E_ENG,
            Wh_C_E_OK: Wh_C_E > E_ENG,
            Wh_C_Not_think: E_ENG === 0,
          }"
        ></div>
        <div class="p-span">
          <iconEte /> <b class="b_wh">{{ Wh_C_E.toFixed(2) }}</b
          >(en Wh/j)
        </div>
      </span>

      <span>
        <div
          :class="{
            Wh_C_NOK: Wh_C_H <= E_ENG,
            Wh_C_E_OK: Wh_C_H > E_ENG,
            Wh_C_Not_think: E_ENG === 0,
          }"
        ></div>
        <div class="p-span">
          <iconHiver /> <b class="b_wh">{{ Wh_C_H.toFixed(2) }}</b
          >(en Wh/j)
        </div>
      </span>

      <span>
        <div
          class="chekVl"
          :class="{
            Wh_C_NOK: P_B_K <= E_ENG,
            Wh_C_E_OK: P_B_K > E_ENG,
            Wh_C_Not_think: E_ENG === 0,
          }"
        ></div>
        <div class="p-span">
          <iconBattre /> <b class="b_wh">{{ P_B_K.toFixed(2) }}</b
          >(en Wh)
        </div>
      </span>

      <span>
        <div
          :class="{
            Wh_C_NOK: P_O_K <= E_WATT,
            Wh_C_E_OK: P_O_K > E_WATT,
            Wh_C_Not_think: E_ENG === 0,
          }"
        ></div>
        <div class="p-span">
          <iconOnduleur /><b class="b_wh">{{ P_O_K.toFixed(2) }}</b
          >(en W )
        </div>
      </span>
    </div>
    <div class="main_rull-1">
      <div class="main-icon">
        <ete-com class="ete" :style="icon_ete" />
        <hiver-com class="hiver" :style="icon_hiver" />
      </div>
      <!-- <span class="chartName">PRODUCTION DU KIT </span> -->
      <a-progress
        style="margin: 4px 0px"
        :percent="ete"
        stroke-color="#ffb800"
        :success="{ percent: hiver, strokeColor: color }"
        :show-info="false"
      />
      <span class="chartName">PRODUCTION DE BATTERIE</span>
      <a-progress
        class="batterieChart"
        style="margin: 4px 0px"
        :percent="battre"
        :show-info="false"
        :stroke-color="color"
      />
    </div>
    <div class="main_rull-2">
      <span class="chartName"
        >VOTRE CONSOMMATION
        <b style="color: #000; font-size: 13px"> {{ E_ENG.toFixed(2) }} </b> (en
        Wh/j)</span
      >
      <a-progress
        class="consomationChart"
        style="margin: 4px 0px"
        :percent="energy"
        :show-info="false"
        :stroke-color="colorB"
      />
    </div>
    <div>
      <ruler-cell :scaleEnd="this.scaleEnd" />
    </div>
  </div>
</template>

<script>
import RulerCell from './../UI/rulerCell.vue'
import eteCom from '../icon/eteCom.vue'
import hiverCom from '../icon/hiverCom.vue'
import iconEte from '../icon/iconEte.vue'
import iconHiver from '../icon/iconHiver.vue'
import iconBattre from '../icon/iconBattre.vue'
import iconOnduleur from '../icon/iconOnduleur.vue'
export default {
  data() {
    return {
      color: '#E22623',
      colorB: '#52c41a',
    }
  },
  components: {
    RulerCell,
    eteCom,
    hiverCom,
    iconEte,
    iconHiver,
    iconBattre,
    iconOnduleur,
  },
  props: [
    'Wh_C_E',
    'Wh_C_H',
    'scaleEnd',
    'Width',
    'P_O_K',
    'P_B_K',
    'E_ENG',
    'E_WATT',
  ],
  computed: {
    ete() {
      return this.compu(this.Wh_C_E)
    },
    hiver() {
      return this.compu(this.Wh_C_H)
    },
    energy() {
      return this.compu(this.E_ENG)
    },
    battre() {
      return this.compu(this.P_B_K)
    },
    icon_ete() {
      return `left: ${
        ((this.Wh_C_E / (this.scaleEnd / 100)) * this.Width) / 100 - 17
      }px;`
    },
    icon_hiver() {
      return `left: ${
        ((this.Wh_C_H / (this.scaleEnd / 100)) * this.Width) / 100 - 17
      }px;`
    },
  },
  methods: {
    compu(vl) {
      this.checkEng()
      return vl / (this.scaleEnd / 100)
    },
    checkEng() {
      if (this.E_ENG >= this.Wh_C_H) {
        this.colorB = '#ffa000'
      }
      if (this.E_ENG >= this.Wh_C_E) {
        this.colorB = '#e22623'
      }
      if (this.E_ENG >= this.P_B_K) {
        this.colorB = '#e22623'
      }
      if (this.E_ENG < this.Wh_C_H) {
        this.colorB = '#52c41a'
      }
    },
    openNotificationWithIcon(typ) {
      notification[typ]({
        message: 'Notification Title',
        description:
          'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      })
    },
  },
  watch: {
    E_ENG() {
      this.checkEng()
    },
  },
}
</script>

<style scoped>
.main_rull {
  background-color: #ffffff;
  margin-top: 20px;
  padding: 1rem;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 7%);
  border: 2px #e6e6e6 solid;
  border-radius: 10px;
}
.main_rull-1 {
  margin-top: 41px;
}
.main-icon {
  height: 10px;
  display: flex;
  position: relative;
  top: 9px;
  z-index: 10;
}
.ete {
  position: absolute;
  top: -31px;
  transition: 1s;
  z-index: 20;
}
.hiver {
  position: absolute;
  top: -31px;
  transition: 1s;
}
.chartName {
  font-size: 12px;
  font-weight: 500;
  color: #808080;
}
.Production {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.Production .p-span {
  display: flex;
  /* gap: 5px; */
  width: 197px;
  height: 57px;
  padding: 8px 4px;
  align-items: center;
  justify-content: space-around;
  background-color: #f5f5f5;
  /* border-top: 5px solid #f6f8f9; */
  border-radius: 5px;
}
.Wh_C_E_OK,
.Wh_C_NOK,
.Wh_C_Not_think {
  margin-bottom: 8px;
  width: 100%;
  height: 7px;
  border-radius: 45px;
}
.Wh_C_E_OK {
  background-color: #47c00b !important;
  box-shadow: 0px 0px 2.5px #47c00b, inset 0px 0px 4px rgba(45, 136, 0, 0.5);
}

.Wh_C_NOK {
  background-color: #e22623 !important;
  box-shadow: 0px 0px 2.5px rgba(226, 38, 35, 0.5),
    inset 0px 0px 4px rgba(147, 2, 0, 0.5);
}
.Wh_C_Not_think {
  background-color: #f6f8f9 !important;
  box-shadow: 0px 0px 2.5px #f6f8f9, inset 0px 0px 4px rgba(246, 248, 249, 0.5);
}
/* ///////////////////////////////////////////////////////////////// */

@media screen and (max-width: 901px) {
  .Production .p-span {
    width: 184px;
    height: 57px;
    padding: 8px 4px;
  }
}
@media screen and (max-width: 865px) {
  .Production .p-span {
    width: 272px;
    height: 57px;
    padding: 8px 4px;
  }
}
@media screen and (max-width: 615px) {
  .Production .p-span {
    width: 229px;
    height: 57px;
    padding: 8px 4px;
  }
}
@media screen and (max-width: 517px) {
  .Production .p-span {
    width: 200px;
    height: 57px;
    padding: 8px 4px;
  }
}

@media screen and (max-width: 459px) {
  .Production .p-span {
    width: 172px;
    height: 57px;
    padding: 8px 4px;
    font-size: 12px;
  }
}
@media screen and (max-width: 459px) {
  .Production .p-span {
    width: 172px;
    height: 57px;
    padding: 8px 4px;
    font-size: 12px;
  }
}
@media screen and (max-width: 402px) {
  .Production .p-span {
    width: 160px;
    height: 57px;
    padding: 8px 4px;
    font-size: 10px;
  }
  .b_wh {
    font-size: 11px;
  }
}
@media screen and (max-width: 379px) {
  .Production .p-span {
    width: 147px;
    height: 50px;
    padding: 8px 4px;
    font-size: 9px;
  }
  .b_wh {
    font-size: 11px;
  }
}
</style>
// ////////////////////////////////////////////////////////// //

<style>
.ant-progress > .ant-progress-outer > .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #ececec;
  border-radius: 100px;
  height: 19px !important;
}
.ant-progress-bg {
  height: 19px !important;
  transition: 1s !important;
}
.ant-progress-success-bg {
  height: 19px !important;
  transition: 1s !important;
}
.ant-progress-outer .ant-progress-success-bg {
  box-shadow: 1px 1px 4px rgba(226, 38, 35, 0.5),
    inset 0px 0px 7px rgba(147, 2, 0, 0.5);
}
.ant-progress-bg {
  box-shadow: 1px 1px 4px rgba(255, 184, 0, 0.5),
    inset 0px 0px 7px rgba(228, 137, 17, 0.5);
}
.batterieChart .ant-progress-bg {
  box-shadow: 1px 1px 4px rgba(226, 38, 35, 0.5),
    inset 0px 0px 7px rgba(147, 2, 0, 0.5);
}
.consomationChart .ant-progress-bg {
  box-shadow: none;
}
@media screen and (max-width: 500px) {
  .ant-progress > .ant-progress-outer > .ant-progress-inner,
  .ant-progress-bg,
  .ant-progress-success-bg {
    height: 15px !important;
  }
}
</style>
