<template>
  <div class="content">
    <a-row
      :class="{ scroull: withScreen < 731 }"
      class="item-a-row"
      justify="space-between"
    >
      <a-col align="center" v-for="item in items" :key="item.id">
        <div class="item-a-div">
          <div class="item-a-div2" @change="goTo" @click="addTo(item)">
            <img :src="item.image" :alt="item.name" />
            <h4>{{ item.name }}</h4>
          </div>
        </div>
      </a-col>
    </a-row>

    <div v-if="this.listItems.length !== 0" class="content_2">
      <button class="btn-remov-all" @click="RemoveAll">
        <icon-remov />
        <span> supprimer tout </span>
      </button>

      <div class="v-content_2">
        <a-row class="content-2">
          <a-col
            class="A_col"
            align="center"
            v-for="vl in listItems"
            :key="vl.id"
          >
            <div id="test">
              <energyItem
                class="listItems"
                :id="vl.id"
                :name="vl.name"
                :energy="vl.energy"
                :image="vl.image"
                :count="vl.count"
                :hours="vl.hours"
                :idImag="vl.idImag"
                @updateCon="updateCon"
                @remove="Remove"
              ></energyItem>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import energyItem from './energyItem.vue'
import iconRemov from './../../icon/iconRemov.vue'
import { nextTick } from 'vue'

export default {
  components: {
    energyItem,
    iconRemov,
  },
  emits: ['updateEng', 'updateWatt'],
  created() {
    window.addEventListener('resize', this.calculWidth)
    window.addEventListener('resize', this.mediaMobaile)
    this.calculWidth()
    this.mediaMobaile()
  },
  mounted() {
    if (localStorage.listItems) {
      this.listItems = JSON.parse(localStorage.listItems)

      if (this.listItems !== 0) {
        this.clculEnergy()
        this.clculWatt()
      }
    }
  },
  data() {
    return {
      spanNumber: 3,
      items: [
        {
          id: null,
          name: 'Éclairage',
          energy: 9,
          count: 1,
          image:
            'https://ng-simulator-off-grid.vercel.app/assets/images/lamp.png',
          hours: 5,
          idImag: 0,
        },
        {
          id: null,
          name: 'TV',
          energy: 80,
          count: 1,
          image:
            'https://ng-simulator-off-grid.vercel.app/assets/images/tv.png',
          hours: 2,
          idImag: 1,
        },
        {
          id: null,
          name: 'Mobile',
          energy: 5,
          count: 1,
          image:
            'https://ng-simulator-off-grid.vercel.app/assets/images/mobile.png',
          hours: 2,
          idImag: 2,
        },
        {
          id: null,
          name: 'Laptop',
          energy: 50,
          count: 1,
          image:
            'https://ng-simulator-off-grid.vercel.app/assets/images/laptop.png',
          hours: 3,
          idImag: 3,
        },
        {
          id: null,
          name: 'Réfrigérateur',
          energy: 20,
          count: 1,
          image:
            'https://ng-simulator-off-grid.vercel.app/assets/images/refrigerator.png',
          hours: 24,
          idImag: 4,
        },
        {
          id: null,
          name: 'Mixeur',
          energy: 300,
          count: 1,
          image:
            'https://ng-simulator-off-grid.vercel.app/assets/images/blender.png',
          hours: 0.25,
          idImag: 5,
        },
        {
          id: null,
          name: 'Autre',
          energy: 100,
          count: 1,
          image:
            'https://ng-simulator-off-grid.vercel.app/assets/images/other.png',
          hours: 1,
          idImag: 6,
        },
      ],
      listItems: [],
      ConEnergy: 0,
      Watt: 0,
      withScreen: null,
    }
  },
  methods: {
    calculWidth() {
      const vl = window.innerWidth
      if (vl < 1200) {
        this.spanNumber = 2
      }
      if (vl < 920) {
        this.spanNumber = 5
      }
      if (vl < 600) {
        this.spanNumber = 7
      }
      if (vl < 400) {
        this.spanNumber = 10
      }
    },
    addTo(el) {
      this.listItems.unshift({
        id: Date.now(),
        name: el.name,
        energy: el.energy,
        count: el.count,
        image: el.image,
        hours: el.hours,
        idImag: el.idImag,
      })

      this.localST(this.listItems)

      this.clculEnergy()
      this.clculWatt()
      if (this.listItems.length === 1) {
        this.increment()
      }
    },
    updateCon(data) {
      localStorage.removeItem('listItems')
      const findIndex = this.listItems.findIndex((el) => el.id === data.id)
      this.listItems[findIndex] = data
      this.localST(this.listItems)
      this.clculEnergy()
      this.clculWatt()
    },
    Remove(data) {
      localStorage.removeItem('listItems')
      this.listItems = this.listItems.filter((el) => el.id !== data)
      this.localST(this.listItems)
      this.clculEnergy()
      this.clculWatt()
    },
    RemoveAll() {
      this.listItems = []
      this.clculEnergy()
      this.clculWatt()
      this.localST(this.listItems)
    },
    clculEnergy() {
      this.ConEnergy = 0
      this.listItems.forEach((el) => {
        let vl = el.energy * el.count * el.hours
        this.ConEnergy = this.ConEnergy + vl
      })

      this.$emit('updateEng', this.ConEnergy)
    },
    clculWatt() {
      this.Watt = 0
      this.listItems.forEach((el) => {
        let vl = el.energy * el.count
        this.Watt = this.Watt + vl
      })
      this.$emit('updateWatt', this.Watt)
    },
    async increment() {
      await nextTick()
      // DOM is now updated
      document.getElementById('test').scrollIntoView({ behavior: 'smooth' })
    },

    localST(obj) {
      if (obj) {
        localStorage.setItem('listItems', JSON.stringify(obj))
      }
    },

    mediaMobaile() {
      const WidthScreen = window.document.querySelector('body').scrollWidth
      this.withScreen = WidthScreen
    },
  },
}
</script>

<style scoped>
/* .content {
} */
.item-a-row {
  width: 100%;
  padding: 20px;
  background: #f6f8f9;
  border: 0.730263px solid #e6e6e6;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 7%);
  border-radius: 5.8421px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.item-a-div2 {
  /* background-color: red; */
  width: 95px;
}
h4 {
  margin-top: 11px;
  color: #050505;
}
.item-a-div2 > img {
  width: 70px;
  height: 70px;
  padding: 10px;
  border-radius: 50%;
  background: #ffffff;
  border: 1.47368px solid #ececec;
  box-shadow: 0px 2.97321px 6.68971px rgba(0, 0, 0, 0.07);
}
.listItems {
  padding: 12px;
  margin: 3px;
  background: #ffffff;
  border: 0.842797px solid #e6e6e6;
  box-shadow: 0px 0px 6.39031px rgba(0, 0, 0, 0.09);
  border-radius: 9.58547px;
}
.v-content_2 {
  display: flex;
  background: #f6f8f9;
  border: 1.47368px solid #ececec;
  box-shadow: 0px 2.97321px 6.68971px rgb(0 0 0 / 7%);
  border-radius: 10px;
  margin: 20px 0;
  padding: 12px 0;
  width: 100%;
  justify-content: center;
}

.content-2 {
  width: 846px;
}
.content_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-remov-all {
  width: 201px;
  border: none;
  padding: 6px 19.8028px;
  background: #e22623;
  box-shadow: 0px 1.71109px 6.84438px rgb(226 38 35 / 35%);
  border-radius: 3.64828px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.btn-remov-all span {
  color: #ffffff;
}
.scroull {
  overflow-y: hidden;
  overflow-x: scroll;
}
/* ////////////////////////////////// */
.fadeIn3 {
  animation-name: fadeInt3;
  animation-duration: 1s;
}

.fadeOut3 {
  animation-name: fadeOut3;
  animation-duration: 1s;
}

@keyframes fadeIn3 {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;

    transform: scale(1);
  }
}

@keyframes fadeOut3 {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;

    transform: scale(1);
  }
}

@media screen and (max-width: 867px) {
  .btn-remov-all {
    width: 221px;
    border: none;
    font-size: 13px;
    font-weight: 500;
    padding: 14px 19.8028px;
    background: #e22623;
    box-shadow: 0px 1.71109px 6.84438px rgb(226 38 35 / 35%);
    border-radius: 3.64828px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 867px) {
  .content-2 {
    width: 564px;
  }
}
@media screen and (max-width: 574px) {
  .content-2 {
    width: 283px;
  }
}
@media screen and (max-width: 500px) {
  .content-2 {
    width: 282px;
  }
}
</style>
