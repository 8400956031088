// import axios from 'axios'

export class convertImg {
  constructor(imag) {
    this.image = imag
  }
  async conv() {
    const vl = await this.getDataBlob(this.image)
    return vl
  }
  async parseURI(d) {
    var reader = new FileReader()
    reader.readAsDataURL(d)
    return new Promise((res, rej) => {
      reader.onload = (e) => {
        res(e.target.result)
      }
    })
  }
  async getDataBlob(url) {
    var res = await fetch(url, {})
    var blob = await res.blob()
    var uri = await this.parseURI(blob)
    return uri
  }
}
