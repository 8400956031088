<template>
  <div class="FormUser">
    <form
      id="app"
      @submit="checkForm"
      action="https://vuejs.org/"
      method="post"
    >
      <h3>Pour télécharger votre devis, merci de remplir ce formulaire:</h3>
      <div class="FormUser-col">
        <label for="name"><span style="color: red">*</span> Name:</label>
        <input id="name" v-model="User.Name" type="text" name="name" required />
      </div>

      <div class="FormUser-col">
        <label for="Email"><span style="color: red">*</span> Email:</label>
        <input
          id="Email"
          v-model="User.Email"
          type="Email"
          name="Email"
          required
        />
      </div>

      <div class="FormUser-col">
        <label for="Tele"><span style="color: red">*</span> Tele (+212):</label>
        <input
          id="tel"
          v-model="User.Tele"
          type="tel"
          name="tel"
          min="0"
          maxlength="10"
          placeholder="06-00-00-00-00"
          required
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
        />
      </div>

      <div class="FormUser-col" style="margin-top: 20px; display: inline-block">
        <input
          type="submit"
          value="Submit"
          style="background-color: blue; color: #fff"
        />
      </div>
    </form>

    <!-- <div v-if="!show">
      <vue-pdf :KitsItem="this.KitsItem" />
    </div> -->
  </div>
</template>

<script>
import VuePdf from './divPDF/VuePdf.vue'
export default {
  props: ['KitsItem'],
  emits: ['htlm2Pdf'],
  components: {
    VuePdf,
  },
  beforeCreate() {
    if (!localStorage.user) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          Name: '',
          Email: '',
          Tele: '',
        })
      )
    }
  },
  data() {
    return {
      User: {
        Name: '',
        Email: '',
        Tele: '',
      },
      show: false,
    }
  },
  methods: {
    checkForm: function (e) {
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(this.User))
      ;(this.User = {
        Name: '',
        Email: '',
        Tele: '',
      }),
        this.$emit('htlm2Pdf', this.show)
      e.preventDefault()
    },
  },
}
</script>

<style>
.FormUser {
  background-color: #dcdcdc;
  padding: 10px;
  border-radius: 10px;
}
.FormUser-col {
  display: flex;
  flex-direction: column;
  width: 30rem;
}
</style>
