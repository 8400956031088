<template>
  <div>
    <a-space class="select_v">
      <a-select
        class="select_v"
        ref="select"
        v-model:value="value1"
        style="width: 120px"
        @change="handleChange"
      >
        <a-select-option value="380">380 V</a-select-option>
        <a-select-option value="220">220 V</a-select-option>
        <a-select-option value="12">12 V</a-select-option>
      </a-select>
    </a-space>
  </div>
</template>

<script>
export default {
  emits: ['changVolt'],
  data() {
    return {
      value1: `${localStorage.tension} V`,
    }
  },
  methods: {
    handleChange() {
      localStorage.setItem('tension', JSON.stringify(+this.value1))
      this.$emit('changVolt', +this.value1)
    },
  },
}
</script>

<style>
.select_v > .ant-select-selector {
  border-color: #ffc700 !important;
}
</style>
