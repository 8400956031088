<template>
  <!-- <div class="icon_cp"> -->
  <!-- style="object-fit: conntain" -->
  <div>
    <img :src="logo" />
  </div>
</template>

<script>
import Logo from '../../../../assets/Logo-web-large.png'
export default {
  data() {
    return {
      logo: Logo,
    }
  },
}
</script>

<style scoped>
.icon_cp img {
  width: 100px;
  height: 90px;
}
.icon_cp {
  width: 100px;
  height: 100px;
}
</style>
