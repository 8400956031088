<template>
  <div class="svg-ete">
    <img :src="hiver" alt="" />
  </div>
</template>

<script>
import chartHiver from './PngIcon/chartHiver.svg'
export default {
  data() {
    return {
      hiver: chartHiver,
    }
  },
}
</script>

<style scoped>
.svg-ete > img {
  height: 204px;
  width: 35px;
}
@media screen and (max-width: 601px) {
  .svg-ete > img {
    height: 199px;
    width: 35px;
  }
}
@media screen and (max-width: 415px) {
  .svg-ete > img {
    height: 194px;
    width: 35px;
  }
}
</style>
