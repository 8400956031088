<template>
  <div>
    <div id="element-to-print">
      <div class="content_1">
        <Icon />
        <div class="content_1-info">
          <span><b>Date:</b> {{ date }}</span>
          <span><b>Date de fin de validité:</b> {{ dateFind }}</span>
          <!-- Date de fin de validité : 31/01/2023 -->
          <span><b>Nom de client:</b> {{ user.Name }}</span>
          <span><b>GSM:</b> {{ user.Tele }}</span>
          <span><b>Email:</b> {{ user.Email }}</span>
        </div>
      </div>

      <div class="content_2">
        <div class="content_2-img">
          <img :src="kitImage" id="WbImag" />
        </div>
        <div class="content_2-info">
          <span><b>kit:</b> {{ this.KitsItem[this.numberKit].name }} </span>
          <span
            ><b>Link: </b>
            <a :href="this.KitsItem[this.numberKit].permalink">
              {{ this.KitsItem[this.numberKit].permalink }}</a
            ></span
          >
          <span>
            <span>
              <span class="price2"
                >{{ this.KitsItem[this.numberKit].price }} Dhs
              </span>
              <b style="margin: 0 6px; color: #ffc700; font-size: 17px">
                {{ price.toFixed(0) }} Dhs
              </b>

              <span> (Economisez 5%)</span>
            </span>
          </span>
        </div>
      </div>
      <div class="content_4-pro">
        <h3>PRODUCTION DU KIT:</h3>
        <div class="content_4-pro-row">
          <div class="Production">
            <span
              :class="{
                Wh_C_NOK: Wh_CEP <= E_ENGP,
                Wh_C_E_OK: Wh_CEP > E_ENGP,
                Wh_C_Not_think: E_ENGP === 0,
              }"
            >
              <iconEte /> ETE <b class="b_wh">{{ Wh_CEP.toFixed(2) }}</b
              >Wh/j</span
            >
            <span
              :class="{
                Wh_C_NOK: Wh_CHP <= E_ENGP,
                Wh_C_E_OK: Wh_CHP > E_ENGP,
                Wh_C_Not_think: E_ENGP === 0,
              }"
              ><iconHiver /> HIVER <b class="b_wh">{{ Wh_CHP.toFixed(2) }}</b
              >Wh/j</span
            >
            <span
              :class="{
                Wh_C_NOK: P_BKP <= E_ENGP,
                Wh_C_E_OK: P_BKP > E_ENGP,
                Wh_C_Not_think: E_ENGP === 0,
              }"
              ><iconBattre /> BATTERIE <b class="b_wh">{{ P_BKP.toFixed(2) }}</b
              >Wh</span
            >
            <span
              :class="{
                Wh_C_NOK: P_OKP <= E_WATTP,
                Wh_C_E_OK: P_OKP > E_WATTP,
                Wh_C_Not_think: E_ENGP === 0,
              }"
              ><iconOnduleur /> ONDULEUR
              <b class="b_wh">{{ P_OKP.toFixed(2) }}</b
              >W</span
            >
          </div>
          <div class="Production1">
            <span><b>Votre tension :</b> {{ tension }} V</span>
            <span><b>Votre zone :</b> {{ latitude }}</span>

            <span><b>Profondeur de batterie :</b> {{ batterie }}% </span>
          </div>
        </div>
      </div>
      <div class="content_3">
        <tableItems />
      </div>
      <div class="content_3">
        <div class="content_3-info">
          <span><b> Total :</b> {{ E_WATTP }} W </span>
          <span><b> Total :</b> {{ E_ENGP.toFixed(2) }} Wh/j </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './icon/cpTch.vue'
import tableItems from './tableItems.vue'
import iconEte from './icon/iconEte.vue'
import iconHiver from './icon/iconHiver.vue'
import iconBattre from './icon/iconBattre.vue'
import iconOnduleur from './icon/iconOnduleur.vue'
export default {
  props: [
    'KitsItem',
    'Wh_CEP',
    'Wh_CHP',
    'S_caleEndP',
    'W_idthP',
    'P_OKP',
    'P_BKP',
    'E_ENGP',
    'E_WATTP',
  ],
  components: {
    Icon,
    tableItems,
    iconEte,
    iconHiver,
    iconBattre,
    iconOnduleur,
  },
  data() {
    return {
      numberKit: JSON.parse(localStorage.kitNumber),
      user: JSON.parse(localStorage.user),
      tension: JSON.parse(localStorage.tension),
      latitude: JSON.parse(localStorage.ZoneNB),
      batterie: `${100 - JSON.parse(localStorage.profondeur) * 100}`,
    }
  },
  computed: {
    kitImage() {
      let el = `${this.KitsItem[this.numberKit].images[0].src}`
      return el.replace('.jpg', '-300x300.jpg')
    },
    price() {
      const clprice = +this.KitsItem[this.numberKit].price
      return clprice - (clprice * 5) / 100
    },
    date() {
      let currentDate = new Date()
      let formattedDate = currentDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      return formattedDate
    },
    dateFind() {
      let currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + 15)
      let formattedDate = currentDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      return formattedDate
    },
  },
  methods: {},
}
</script>

<style scoped>
.content_1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.content_1-info {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: right;
}
.content_2 {
  display: flex;
  flex-direction: row;
  background-color: #efefef;
  padding: 10px;
  border-radius: 10px;
  margin-top: 30px;
  gap: 21px;
}
.content_2-img img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.content_2-info {
  display: flex;
  flex-direction: column;
}
.content_2 span:not(:last-child) {
  margin-bottom: 10px;
}
.content_3 {
  margin: 25px 0;
}
.content_2-DH {
  color: #ffc700 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}
.price2 {
  text-decoration: line-through;
}
.content_4-pro {
  margin: 10px 0;
  /* padding: 10px;
  border: 2px solid #f60000;
  border-radius: 10px; */
}
.content_4-pro-row {
  display: flex;
  flex-direction: row;
}
.Production1 {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 21px;
  padding: 16px;
  border: 2px solid #f60000;
  border-radius: 10px;
  width: 15rem;
}
.Production1 > span {
  margin: 5px 0;
  font-size: 13px;
}

.Production {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  gap: 9px;
  flex-wrap: wrap;
  width: 35rem;
  padding: 13px;
  border: 2px solid #f60000;
  border-radius: 10px;
}
.Production > span {
  display: flex;
  gap: -1px;
  /* width: 260px; */
  padding: 5px 10px;
  align-items: center;
  justify-content: space-around;
  background-color: #f6f8f9;
  border-top: 5px solid #f6f8f9;
  width: 222px;
}
.content_3-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.content_3-info span {
  background-color: #efefef;
  margin: 5px 3px;
  padding: 10px 20px;
}

.Wh_C_E_OK {
  border-top-color: #00f604 !important;
}

.Wh_C_NOK {
  border-top-color: #f60000 !important;
}
.Wh_C_Not_think {
  border-top-color: #f6f8f9 !important;
}
</style>
